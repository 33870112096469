<template>
  <div class="">
    <div :class="isMobile ? 'news-m' : 'news'">
      <div class="news-content"> 
        <div class="word">
          <div class="word-title">最新动态</div>
          <div class="word-gray"></div>
          <div class="word-text">NEWS</div>
        </div>
        <div v-for="(item, index) in newsLastArr" :key="item.title">
          <div class="content content-left" v-if="index%2===0 || isMobile">
            <div class="imgPart">
              <img class="bigImg" :src="require(`./../../assets/news/${item.imgUrl}`)" />
            </div>
            <div class="content-word">
              <div class="title">{{item.title}}</div>
              <div class="date">{{item.date}}</div>
              <div class="text">{{item.text}}</div>
            </div>
          </div>
          <div class="content content-right"  v-else>
            <div class="content-word">
              <div class="title">{{item.title}}</div>
              <div class="date">{{item.date}}</div>
              <div class="text">{{item.text}}</div>
            </div>
            <div class="imgPart">
              <img class="bigImg" :src="require(`./../../assets/news/${item.imgUrl}`)" />
            </div>
          </div>
        </div>
        <div class="seeMore" v-show="newsLastArrAll.length > newsLastArr.length"> 
          <div @click="seeMore()" class="seeMoreBtn">查看更多</div>
        </div>
        <div class="word">
          <div class="word-title">历史动态</div>
          <div class="word-gray"></div>
          <div class="word-text">HISTORICAL TRENDS</div>
        </div>
        <div v-for="(index) in currentSize" :key="index" class="oldNewsList"> 
          <div class="content-old" v-if="(currentSize*(currentPage-1)+index-1)<newsOldArr.length">
            <div class="imgPart">
              <img class="bigImg" :src="require(`./../../assets/news/${newsOldArr[currentSize*(currentPage-1)+index-1].imgUrl}`)" />
            </div>
            <div class="content-word">
              <div class="title">{{newsOldArr[currentSize*(currentPage-1)+index-1].title}}</div>
              <div class="date">{{newsOldArr[currentSize*(currentPage-1)+index-1].date}}</div>
              <div class="text">{{newsOldArr[currentSize*(currentPage-1)+index-1].text}}</div>
            </div>
          </div>
        </div>
        <div class="page"> 
          <el-pagination
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :page-size="currentSize"
            :total="7">
          </el-pagination>
          <span class="total">共{{Math.ceil(newsOldArr.length/currentSize)}}页</span>
        </div>
      </div>

    </div>
    <Theme></Theme>
  </div>

</template>

<script>
import arrJson from '@/utils/arrJson';
import Theme from "@/components/Theme.vue"

export default {
  name: 'News',
  components: {
    "Theme": Theme, //公司概况
  },
  data() {
    return {
      isMobile: window.innerWidth < 780,
      newsLastArr: [],
      newsLastArrAll: arrJson.lastNews,
      newsOldArr: arrJson.oldNews,
      currentPage: 1,
      currentSize: 6
    }
  },
  methods: {
    handleCurrentChange(page){
      this.currentPage = page
    },
    seeMore() {
      if(this.newsLastArr.length < this.newsLastArrAll.length) {
        const num = this.newsLastArr.length
        this.newsLastArr = this.newsLastArrAll.slice(0, num+2)
      }
    },
    initFunc() {
      this.isMobile = window.innerWidth < 780
    }
  },
  props: {
    msg: String
  },
  mounted() {
    this.newsLastArr = this.newsLastArrAll.slice(0, 2)
    window.addEventListener("resize", this.initFunc)
  },
  destroyed() {
    window.removeEventListener("resize", this.initFunc)
  }
}
</script>


<style scoped lang="less">
  .news{
    background: #f5f6fa;
    .news-content{
      max-width: 1000px;
      margin: 0 auto;
      text-align: center;
      .word{
        .word-title{
          font-size: 26px;
          line-height: 36px;
          font-weight: bold;
          color: #333333;
          letter-spacing: 2px;
          padding: 50px 0 10px;
          text-align: center;
        }
        .word-gray{
          width: 80px;
          height: 2px;
          background: #d6d6d6;
          margin: 0 auto;
        }
        .word-text{
          font-size: 16px;
          line-height: 50px;
          color: #758292 ;
          text-align: center;
          margin-bottom: 30px;
        }
      }
      .content{
       text-align: left;
       margin-bottom: 60px;
        .imgPart{
          display: inline-block;
          width: 34%;
          vertical-align: middle;
          .bigImg{
            width: 100%;
            height: 306px;
            display: block;
            border-radius: 10px;
          }
        }
        .content-word{
          display: inline-block;
          width: 57%;
          vertical-align: middle;
          background: #f4f3f3;
          border: 1px solid #d3d3d5;
          padding: 2% 3.6% 2.8% 2.6%;
          .date{
            color: #8d8d8d;
            font-size: 16px;
            line-height: 34px;
            display: inline-block;
            width: 20%;
          }
          .title{
            color: #292929;
            font-size: 21px;
            line-height: 34px;
            display: inline-block;
            font-weight: bold;
            width: 80%;
            margin-bottom: 10px;
          }
          .text{
            color: #636363;
            font-size: 15px;
            line-height: 26px;
            text-align: left;
            text-indent: 32px;
          }
        }
      }
      .content-left{
        .content-word{
          border-bottom-right-radius: 10px;
          border-top-right-radius: 10px;
          text-align: left;
          border-left: none;
        }
        .date{
          text-align: right;
        }
      }
      .content-right{
        .content-word{
          border-bottom-left-radius: 10px;
          border-top-left-radius: 10px;
          text-align: right;
          border-right: none;
        }
        .title{
          text-align: left;
        }
      }
      .seeMore{
        text-align: center;
        padding-bottom: 40px;
        border-bottom: 1px solid #e3e3e3;
        .seeMoreBtn{
          font-size: 16px;
          color: #0089fe;
          border: 1px solid #0089fe;
          padding: 14px 40px;
          display: inline-block;
          margin: 50px auto 0;
          cursor: pointer;
        }
      }
      .oldNewsList{
        text-align: left;
        margin-bottom: 60px;
        .imgPart{
          display: inline-block;
          width: 20%;
          vertical-align: middle;
          .bigImg{
            width: 100%;
            // height: 210px;
            display: block;
            border-radius: 10px;
          }
        }
        .content-word{
          display: inline-block;
          width: 78%;
          vertical-align: top;
          padding: 0 0 0 2%;
          .date{
            color: #696969;
            font-size: 18px;
            line-height: 34px;
            display: inline-block;
            width: 20%;
            text-align: right;
          }
          .title{
            color: #000000;
            font-size: 18px;
            line-height: 34px;
            display: inline-block;
            width: 80%;
          }
          .text{
            color: #636363;
            font-size: 15px;
            line-height: 26px;
            border-top: 1px #cccdd1 solid;
            padding-top: 14px;
            margin-top: 10px;
            text-align: left;
            text-indent: 32px;
          }
        }
      }
      .page{
        display: inline-block;
        border: 1px solid #cccdd1;
        margin: 20px 0 60px;
        padding: 2px 16px 2px 6px;
        /deep/ .el-pagination{
          display: inline-block;
          .btn-next{
            background: unset;
          }
          .btn-prev{
            background: unset;
          }
          li{
            background: unset;
            min-width: 24px;
            height: 24px;
            line-height: 24px;
            margin: 2px 8px;
          }
          li.active{
            background: #0d55df;
            border-radius: 50%;
            color: #fff;
          }
        }
        .total{
          margin-left: 20px;
          line-height: 36px;
        }
      }
    }
  }

  .news-m{
    background: #f5f6fa;
    .news-content{
      margin: 0 0.2rem;
      text-align: center;
      padding: 0.4rem 0;
      .word{
        .word-title{
          font-size: 0.5rem;
          line-height: 0.8rem;
          font-weight: bold;
          color: #333333;
          letter-spacing: 0.05rem;
          margin: 0 0 0.24rem;
          text-align: center;
        }
        .word-gray{
          width: 1.7rem;
          height: 0.05rem;
          background: #d6d6d6;
          margin: 0 auto;
        }
        .word-text{
          font-size: 0.36rem;
          line-height: 0.8rem;
          color: #758292 ;
          text-align: center;
        }
      }
      .content{
       text-align: left;
       margin-bottom: 0.6rem;
        .imgPart{
          display: inline-block;
          width: 70%;
          vertical-align: middle;
          .bigImg{
            width: 100%;
            height: 5rem;
            display: block;
            border-radius: 10px;
          }
        }
        .content-word{
          display: inline-block;
          width: 90%;
          vertical-align: middle;
          background: #f3f3f3;
          border: 0.02rem solid #d3d3d5;
          padding: 2% 3.6% 2.8% 2.6%;
          .date{
            color: #8d8d8d;
            font-size: 0.4rem;
            line-height: 0.7rem;
            display: inline-block;
            width: 35%;
          }
          .title{
            color: #292929;
            font-size: 0.44rem;
            line-height: 0.7rem;
            display: inline-block;
            font-weight: bold;
            width: 65%;
            margin-bottom: 0.2rem;
          }
          .text{
            color: #636363;
            font-size: 0.38rem;
            line-height: 0.7rem;
            text-align: left;
            text-indent: 0.7rem;
          }
        }
      }
      .content-left{
        .content-word{
          border-radius: 0.3rem;
          text-align: left;
        }
        .date{
          text-align: right;
        }
      }
      .content-right{
        .content-word{
          border-radius: 0.3rem;
          text-align: right;
        }
        .title{
          text-align: left;
        }
      }
      .seeMore{
        text-align: center;
        border-bottom: 1px solid #e3e3e3;
        margin-bottom: 0.4rem;
        .seeMoreBtn{
          font-size: 0.36rem;
          color: #0089fe;
          border: 0.02rem solid #0089fe;
          padding: 0.3rem 0.8rem;
          display: inline-block;
          margin: 0.2rem auto 0.8rem;
        }
      }
      .oldNewsList{
        text-align: left;
        margin-bottom: 1.2rem;
        .imgPart{
          display: inline-block;
          width: 20%;
          vertical-align: middle;
          .bigImg{
            width: 100%;
            height: 1.8rem;
            display: block;
            border-radius: 0.24rem;
          }
        }
        .content-word{
          display: inline-block;
          width: 74%;
          vertical-align: top;
          padding: 0 0 0 2%;
          .date{
            color: #696969;
            font-size: 0.36rem;
            line-height: 0.7rem;
            text-align: right;
          }
          .title{
            color: #000000;
            font-size: 0.44rem;
            line-height: 0.7rem;
            display: inline-block;
          }
          .text{
            color: #636363;
            font-size: 0.38rem;
            line-height: 0.74rem;
            border-top: 0.02rem #cccdd1 solid;
            padding-top: 0.3rem;
            text-align: left;
            text-indent: 0.8rem;
          }
        }
      }
      .page{
        display: inline-block;
        border: 0.02rem solid #cccdd1;
        margin: 0.5rem 0 1.3rem;
        padding: 0.05rem 0.4rem 0.04rem 0.15rem;
        font-size: 0.36rem;
        line-height: 0.8rem;
        /deep/ .el-pagination{
          display: inline-block;
          .btn-next{
            background: unset;
            font-size: 0.4rem;
            height: 0.8rem;
            line-height: 0.8rem;
          }
          .btn-prev{
            background: unset;
            font-size: 0.4rem;
            height: 0.8rem;
            line-height: 0.8rem;
          }
          li{
            background: unset;
            font-size: 0.3rem;
            height: 0.8rem;
            line-height: 0.8rem;
          }
        }
      }
    }
  }


</style>
