<template>
  <div :class="isMobile ? 'join-m' : 'join'">
    <div class="word">
      <div class="word-title">开放的职位</div>
      <div class="word-gray"></div>
      <div class="word-text">OPEN POSITIONS</div>
    </div>
    <div class="content">
      <el-collapse v-model="activeName" accordion class="content-part">
        <el-collapse-item v-for="(item, index) in jobList" :title="item.name" :key="index" :name="index">
          <div v-for="(obj, i) in item.contentList" :key="i">
            <div class="job-title">{{obj.title}}:</div>
            <div class="job-text">
              <p v-for="(one, sort) in obj.con" :key="sort">
                {{one}}
              </p>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <Theme bg="#f3f4f9"></Theme>
  </div>
</template>
  
<script>
  import Theme from "@/components/Theme.vue"
  import arrJson from '@/utils/arrJson'

  export default {
    name: 'Join',
    components: {
      "Theme": Theme, //公司概况
    },
    data() {
      return {
        isMobile: window.innerWidth < 780,
        activeName: 1,
        jobList: arrJson.jobList
      }
    },
    props: {
      msg: String
    },
    mounted() {
      window.addEventListener("resize", this.initFunc)
    },
    methods: {
      initFunc() {
        this.isMobile = window.innerWidth < 780
      }
    },
    destroyed() {
      window.removeEventListener("resize", this.initFunc)
    },
  }
  </script>
  
  
  <style scoped lang="less">
    /deep/.el-collapse-item__header{
      background: #f1f2f4;
      color: #1669f7;
    }
    /deep/.el-collapse-item__wrap{
      background: #f1f2f4;
    }
    /deep/ .el-collapse{
        border: none;
    }
    /deep/ .el-icon-arrow-right:before {
      border: 1px solid;
      border-radius: 50%;
      font-size: 14px;
      padding: 2px;
    }
    .join{
      .word{
        .word-title{
          font-size: 26px;
          line-height: 36px;
          font-weight: bold;
          color: #333333;
          letter-spacing: 2px;
          padding: 50px 0 10px;
          text-align: center;
        }
        .word-gray{
          width: 80px;
          height: 2px;
          background: #d6d6d6;
          margin: 0 auto;
        }
        .word-text{
          font-size: 16px;
          line-height: 50px;
          color: #758292 ;
          text-align: center;
          margin-bottom: 30px;
        }
      }
      .content{
        max-width: 1000px;
        margin: 40px auto 60px;
        /deep/.el-collapse-item{
          margin-bottom: 16px;
        }
        /deep/.el-collapse-item__header{
          padding: 0 46px;
          font-size: 18px;
          height: 60px;
        }
        /deep/.el-collapse-item__wrap{
          border-top: 1px dashed #becdf4;
          padding: 0 46px;
          font-size: 16px;
        }
        /deep/.el-collapse-item__content{
          .job-title{
            font-size: 18px;
            line-height: 20px;
            margin: 40px 0 14px;
          }
          .job-text{
            font-size: 14px;
            line-height: 28px;
          }
          p{
            margin: 0 0;
          }
        }
      }
    }
    .join-m{
      .word{
        .word-title{
          font-size: 0.5rem;
          line-height: 0.8rem;
          font-weight: bold;
          color: #333333;
          letter-spacing: 0.05rem;
          margin: 0.4rem 0 0.24rem;
          text-align: center;
        }
        .word-gray{
          width: 1.7rem;
          height: 0.05rem;
          background: #d6d6d6;
          margin: 0 auto;
        }
        .word-text{
          font-size: 0.32rem;
          line-height: 0.8rem;
          color: #758292 ;
          text-align: center;
        }
      }
      .content{
        margin: 0.4rem 0.2rem;
        /deep/.el-collapse-item{
          margin-bottom: 0.4rem;
        }
        /deep/.el-collapse-item__header{
          padding: 0 0.3rem;
          font-size: 0.4rem;
          height: 1.2rem;
        }
        /deep/.el-collapse-item__wrap{
          border-top: 1px dashed #becdf4;
          padding: 0 0.4rem;
          font-size: 0.34rem;
        }
        /deep/.el-collapse-item__content{
          padding-bottom: 0.4rem;
          .job-title{
            font-size: 0.4rem;
            line-height: 0.5rem;
            color: #505052;
            margin: 0.8rem 0 0.3rem;
          }
          .job-text{
            font-size: 0.36rem;
            line-height: 0.6rem;
          }
          p{
            margin: 0 0;
          }
        }
      }
    }
  
  </style>
  