import vueRouter from "vue-router"
import Home from "../view/Home/Home.vue"
import Detail from "../view/Detail/Detail.vue"
import Project from "../view/Project/Project.vue"
import ProjectDetail from "../view/Project/ProjectDetail.vue"
import News from "../view/News/News.vue"
import Gain from "../view/Gain/Gain.vue"
import About from "../view/About/About.vue"
import Join from "../view/Join/Join.vue"
import Nanzhuang from "../view/Nanzhuang/Nanzhuang.vue"
import NanZhuangOneDetail from "../view/Nanzhuang/NZOneStation.vue"
import Daqi from "../view/Daqi/Daqi.vue"
import DaqiOneDetail from "../view/Daqi/DaqiOneStation.vue"

const router = new vueRouter({
    routes: [
        {
            path: '/',
            redirect: '/home'
        }, {
            path: '/home',
            name: 'home',
            component: Home,
            meta: {
                title: "艾感科技(广东)有限公司"
            }
        }, {
            path: '/join',
            name: 'join',
            component: Join,
            meta: {
                title: "艾感科技(广东)有限公司"
            }
        }, {
            path: '/product',
            name: 'product',
            component: Project,
            meta: {
                title: "艾感科技(广东)有限公司"
            }
        }, {
            path: '/productDetail/:id',
            name: 'productDetail',
            component: ProjectDetail,
            meta: {
                title: "艾感科技(广东)有限公司"
            }
        }, {
            path: '/news',
            name: 'news',
            component: News,
            meta: {
                title: "艾感科技(广东)有限公司"
            }
        }, {
            path: '/gain',
            name: 'gain',
            component: Gain,
            meta: {
                title: "艾感科技(广东)有限公司"
            }
        }, {
            path: '/about',
            name: 'about',
            component: About,
            meta: {
                title: "艾感科技(广东)有限公司"
            }
        }, {
            path: '/nanzhuang',
            name: 'nanzhuang',
            component: Nanzhuang,
            meta: {
                title: "南庄大气监控"
            }
        }, {
            path: '/NanZhuangOneDetail/:id',
            name: 'NanZhuangOneDetail',
            component: NanZhuangOneDetail,
            meta: {
                title: "南庄大气监控"
            }
        }, {
            path: '/daqi',
            name: 'daqi',
            component: Daqi,
            meta: {
                title: "艾感大气监控"
            }
        }, {
            path: '/DaqiOneDetail/:id',
            name: 'DaqiOneDetail',
            component: DaqiOneDetail,
            meta: {
                title: "艾感大气监控"
            }
        }
    ]
})
router.beforeEach((to, from, next) => {
    window.document.title = (to.meta.title).toString();
    next();
})
export default router;