<template>
    <div class="daqi">
      <div class="map-box">
        <div id="map" style="height: 6rem;" class="tMapBox"></div>
      </div>
      <div class="content">
        <div class="device-part">
          <div class="device-name">
            <span class="back-icon" @click="returnTo()"><i class="el-icon-arrow-left"></i></span>
            {{ currentObj.deviceName }}
          </div>
          
          <span class="toptime">{{ topTime }}</span>
        </div>
        <div class="table-box">
          <table>
            <tr>
              <th>电量</th>
              <th>AIR维护时间</th>
              <th>PM维护时间</th>
              <th>状态</th>
            </tr>
            <template>
                <tr>
                  <td>{{currentObj.batteryInfo}}%
                    <el-tooltip v-if="currentObj.batteryInfo<= 30" class="item" effect="dark" content="电量过低" placement="top-start">
                      <i class="el-icon-question text-red"></i>
                    </el-tooltip>
                  </td>
                  <td>{{currentObj.airTime}}
                    <el-tooltip v-if="currentObj.repairAir" class="item" effect="dark" content="空气传感器维护时间已到期" placement="top-start">
                      <i class="el-icon-question text-red"></i>
                    </el-tooltip>
                  </td>
                  <td>{{currentObj.pmTime}}
                    <el-tooltip v-if="currentObj.repairPM" class="item" effect="dark" content="PM传感器维护时间已到期" placement="top-start">
                      <i class="el-icon-question text-red"></i>
                    </el-tooltip>
                  </td>
                  <td>{{currentObj.status}}
                    <el-tooltip v-if="currentObj.status == '维护'" class="item" effect="dark" content="站点异常" placement="top-start">
                      <i class="el-icon-question text-red"></i>
                    </el-tooltip>
                  </td>
                </tr>
            </template>
            <!-- <tr v-else>
              <td colspan="4"><div class="nodata green-text">设备正常</div></td>
            </tr> -->
          </table>
        </div>
        <div class="content-title">
          <span>小时平均曲线</span>
        </div>
        <div class="hour-echart">
          <div ref="hourchart" :style="noHour ? 'height:3rem' : 'height:8rem'"></div>
          <div class="noData" v-if="noHour">暂无数据</div>
        </div>
        
        <div class="content-title">
          <span>污染物浓度限制</span>
        </div>
        <div class="table-box">
          <table>
            <tr>
              <th>污染物</th>
              <th>优</th>
              <th>良</th>
              <th>轻</th>
              <th>中</th>
              <th>重</th>
              <th>严</th>
            </tr>
            <tr v-for="(item, i) in aqiList">
              <td>{{ item.name }}</td>
              <td>{{ item.a }}</td>
              <td>{{ item.b }}</td>
              <td>{{ item.c }}</td>
              <td>{{ item.d }}</td>
              <td>{{ item.e }}</td>
              <td>{{ item.f }}</td>
            </tr>
          </table>
        </div>
        <div class="content-title">
          <span>AQI曲线图</span>
        </div>
        <div class="aqi-echart">
          <div ref="linechart" :style="noAqi ? 'height:3rem' : 'height:8rem'"></div>
          <div class="noData" v-if="noAqi">暂无数据</div>
        </div>
      </div>
    </div>
  </template>
  <script>
    import {request} from "./../../request/request"
    import arrJson from "../../utils/arrJson"
    import moment from "moment"
    import * as L from "leaflet";
    import "leaflet/dist/leaflet.css";
    export default {
      name: "NZOneStaion",
      data(){
        return {
          macAddress: "",
          currentObj: {},
          topTime: "",
          aqiList: arrJson.aqiList,
          aqiEchart: null,
          noAqi: true,
          noHour: true,
          hourchart: null
        }
      },
      mounted(){
        this.topTime = moment().format("YYYY-MM-DD HH:mm:ss")
        this.getId()
      },
      methods: {
        getId() {
          const pathList = this.$router.currentRoute.path.split("/") // 取url上的id
          this.macAddress = pathList[pathList.length-1]
          this.currentObj = JSON.parse(window.sessionStorage.getItem("currentOne"))
          this.getDetail()
          this.setMap()
        },
        setMap() {
          var map = L.map('map').setView(L.latLng(this.currentObj.lat, this.currentObj.lon), 14);
          L.tileLayer('/tile/{z}/{x}/{y}.png', {
            maxZoom: 19,
          }).addTo(map);
          const Icon = L.icon({
            iconUrl: require("@/assets/icon/ico_dw.png"),
            iconSize: [12, 15], // 加上，fixed marker在放缩5 时偏差比较大的问题
            iconAnchor: [6, 6],
            popupAnchor: [0, 0]
          })
          var marker = L.marker([this.currentObj.lat, this.currentObj.lon], {
            icon: Icon,
          }).addTo(map);
  
          const oneHtml = "<div class='map-subtitle' style='color: "+this.currentObj.color+"'>"+this.currentObj.deviceName+"</div>"+
                          "<div class='map-detail' style='color: "+this.currentObj.so2Quality.color+"'>SO2："+this.currentObj.SO2+"</div>"+
                          "<div class='map-detail' style='color: "+this.currentObj.no2Quality.color+"'>NO2："+this.currentObj.NO2+"</div>"+
                          "<div class='map-detail' style='color: "+this.currentObj.pm10Quality.color+"'>PM10："+this.currentObj.PM10+"</div>"+
                          "<div class='map-detail' style='color: "+this.currentObj.pm25Quality.color+"'>PM25："+this.currentObj.PM25+"</div>"+
                          "<div class='map-detail' style='color: "+this.currentObj.coQuality.color+"'>CO："+this.currentObj.CO+"</div>"+
                          "<div class='map-detail' style='color: "+this.currentObj.o3Quality.color+"'>O3："+this.currentObj.O3+"</div>"
          marker.bindPopup(oneHtml, {maxWidth: 200, className: "station-detail"}).openPopup();
  
          // const htmlContent = '<div style="color:#ec2525">'+this.currentObj.deviceName+'</div'
          // marker.bindPopup(htmlContent, {maxWidth: 200, className: "station-detail"}).openPopup();
        },
        getDetail(){
          request({
            url: "device/smartAirHourHistory",
            method: "get",
            params: {
              macAddress: this.macAddress,
              beginTime: moment().add(-2, 'days').startOf("day").format("YYYY-MM-DD"),
              endTime: moment().format("YYYY-MM-DD")
            }
          }).then(res => {
            if(res.data.successful){
              this.setHourChart(res.data.data)
            }
          });
          request({
            url: "device/macDayIAQI",
            method: "get",
            params: {
              macAddress: this.macAddress,
              beginTime: moment().add(-6, 'days').startOf("day").format("YYYY-MM-DD"),
              endTime: moment().format("YYYY-MM-DD")
            }
          }).then(res => {
            if(res.data.successful){
              this.setAqiChart(res.data.data)
            }
          });
        },
        setHourChart(data){
          if(data.length>0){
            this.noHour = false
            var option = {
              xAxis: {
                type: 'category',
                data: data.map(item => {
                  return item.beginTime
                })
              },
              yAxis: {
                type: 'value'
              },
              grid: {
                top: "20%"
              },
              legend: {
                data: ['SO2', 'NO2', 'CO', 'O3', 'PM10', 'PM25', '温度'],
                itemStyle: {
                  opacity: 0
                }
              },
              tooltip: {
                trigger: 'axis',
                formatter: function(params){
  
                  let thisHtml = '<div>'
                  params.forEach(item => {
                    console.log(item)
                    const unit = item.seriesName==='温度' ? '°C' : 
                                item.seriesName==='CO' ? 'mg/m³' : 'ug/m³'
                    thisHtml +='<div><i class="tooltip-icon" style="background: '+item.color+';"></i>'
                    thisHtml +='<span>'+item.seriesName+': &nbsp</span>'
                    thisHtml +='<span>'+item.value+'</span>'+unit+'</div>'
                  })
                  thisHtml += "</div>"
                  return thisHtml
                }
              },
              series: [
                {
                  data: data.map(item => {
                    return item.SO2
                  }),
                  name: "SO2",
                  type: 'line',
                  smooth: true
                },  {
                  data: data.map(item => {
                    return item.NO2
                  }),
                  name: "NO2",
                  type: 'line',
                  smooth: true
                },  {
                  data: data.map(item => {
                    return item.CO
                  }),
                  name: "CO",
                  type: 'line',
                  smooth: true
                },  {
                  data: data.map(item => {
                    return item.O3
                  }),
                  name: "O3",
                  type: 'line',
                  smooth: true
                },  {
                  data: data.map(item => {
                    return item.PM10
                  }),
                  name: "PM10",
                  type: 'line',
                  smooth: true
                },  {
                  data: data.map(item => {
                    return item.PM25
                  }),
                  name: "PM25",
                  type: 'line',
                  smooth: true
                },  {
                  data: data.map(item => {
                    return item.temp
                  }),
                  name: "温度",
                  type: 'line',
                  smooth: true
                }
              ]
            };
            const t = this
            setTimeout(function(){
              if(!t.hourchart){
                t.hourchart = t.$echarts.init(t.$refs.hourchart);
              }
              t.hourchart.clear();
              option && t.hourchart.setOption(option);
            },300 )
          } else {
            this.noHour = true
          }
        },
        setAqiChart(data){
          if(data.time.length>0){
            this.noAqi = false;
            var option = {
              xAxis: {
                type: 'category',
                data: data.time
              },
              yAxis: {
                type: 'value'
              },
              grid: {
                top: "10%"
              },
              series: [
                {
                  data: data.AQI,
                  type: 'line',
                  smooth: true
                }
              ]
            };
            const t = this
            setTimeout(function(){
              if(!t.aqiEchart){
                t.aqiEchart = t.$echarts.init(t.$refs.linechart);
              }
              t.aqiEchart.clear();
              option && t.aqiEchart.setOption(option);
            },300 )
          } else {
            this.noAqi = true
          }
          
        },
        returnTo() {
          this.$router.push({ path: '/daqi' })
        }
      }
    }
  </script>
  <style lang="less">
    .back-icon{
      border: 0.02rem solid #e5e5e5;
      padding: 0.1rem 0.2rem;
      background: #0081ff;
      border-radius: 0.2rem;
      font-size: 0.4rem;
      color: #fff;
    }
    .tooltip-icon{
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 6px;
    }
    .station-detail{
      font-size: 0.3rem;
    }
    .daqi .content{
      padding: 0.4rem;
      background-color: #f5f5f5;
      .device-part{
         position: relative;
        .device-name{
          font-size: 0.48rem;
          font-weight: bold;
        }
        .toptime{
          position: absolute;
          font-size: 0.34rem;
          bottom: 0;
          right: 0;
          color: #969696;
        }
      }
      .content-title{
        font-size: 0.46rem;
        font-weight: bold;
        position: relative;
        margin: 0.4rem 0 0.2rem;
      }
      .content-title::before{
        content: "";
        width: 0.14rem;
        background-color: #39b54a;
        border-radius: 0.2rem;
        display: inline-block;
        height: 0.4rem;
        margin-right: 0.3rem;
        vertical-align: middle;
      }
    }
    .table-box{
      font-size: 0.3rem;
      background-color: #fff;
      border-radius: 0.4rem;
      margin-top: 0.6rem;
      table{
        width: 100%;
      }
      th{
        line-height: 1.2rem;
        color: #a9a9a9;
        font-weight: normal;
      }
      td{
        text-align: center;
        border-top: 0.02rem solid #eee;
        line-height: 0.8rem;
      }
    }
    .text-red{
      color: rgb(249, 52, 52);
    }
    .hour-echart{
      position: relative;
      .noData{
        position: absolute;
        bottom: 2rem;
        left: 3.4rem;
        text-align: center;
        font-size: 0.4rem;
      }
    }
    .aqi-echart{
      position: relative;
      .noData{
        position: absolute;
        bottom: 2rem;
        left: 3.4rem;
        text-align: center;
        font-size: 0.4rem;
      }
    }
  </style>