<template>
  <div :class="isMobile ? 'main-m' : 'main'">
    <Theme></Theme>
    <div class="overview">
      <div class="overview-title">产品服务</div>
      <div class="overview-dec">让每一步操作都合理且舒适，给用户优化的体验</div>
      <div class="overview-content" ref="overviewRef">
        <div v-show="isMobile" class="overview-middle-img"></div>
        <div class="overview-con-left" :class="{overviewMove:testShow}">
          <div v-for="(item) in overviewListLeft" :key="item.title" class="overview-con-one">
            <div class="overview-con-img-box">
              <img class="overview-con-img-bg" :src="imgUrl.overViewbg" />
              <img class="overview-con-img" :src="item.img" />
            </div>
            <div class="overview-con-word">
              <div class="overview-con-title">{{ item.title }}</div>
              <div class="overview-con-con">{{ item.content }}</div>
            </div>
          </div>
        </div>
        <div class="overview-con-right" v-if="isMobile">
          <div v-for="(item) in overviewListRight" :key="item.title" class="overview-con-one">
            <div class="overview-con-img-box">
              <img class="overview-con-img-bg" :src="imgUrl.overViewbg" />
              <img class="overview-con-img" :src="item.img" />
            </div>
            <div class="overview-con-word">
              <div class="overview-con-title">{{ item.title }}</div>
              <div class="overview-con-con">{{ item.content }}</div>
            </div>
          </div>
        </div>
        <div class="overview-con-right"  :class="{overviewMove:testShow}" v-else>
          <div v-for="(item) in overviewListRight" :key="item.title" class="overview-con-one">
            <div class="overview-con-word">
              <div class="overview-con-title">{{ item.title }}</div>
              <div class="overview-con-con">{{ item.content }}</div>
            </div>
            <div class="overview-con-img-box">
              <img class="overview-con-img-bg" :src="imgUrl.overViewbg" />
              <img class="overview-con-img" :src="item.img" />
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- 知识产权 -->
    <div class="word">
      <div class="word-title" ref="wordRef" :class="{titleMove: isTitleMove}">
        <div class="word-big">知识产权</div>
      </div>
    </div>
    <div class="bannerList">
      <div class="swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, i) in intellectualList" :key="i">
            <div class="swipperItem">
              <img style="width: 100%;height: 100%;" :src="item.url" />
              <div class="img-up-word">
                <div class="img-up-word-content">{{item.title}}</div>
                <div class="img-up-word-dec">{{item.date}}</div>
              </div>
            </div></div>
        </div>
        <div class="swiper-pagination-intellectual"></div>
      </div>
    </div>

    <!-- 证书与荣誉 -->
    <div class="honor-part">
      <div class="word">
        <div class="word-title" ref="honorRef" :class="{titleMove: isHonorMove}">
          <div class="word-big">证书与荣誉</div>
        </div>
      </div>
      <div class="bannerList">
        <div class="swiper-honor">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, i) in honorList" :key="i">
              <div class="swipperItem">
                <div class="imgBox">
                  <img class="honor-img" :src="item.urlUp" />
                </div>
                <div class="imgBox">
                  <img class="honor-img honor-img-down" :src="item.urlDown" />
                </div>
                
              </div></div>
          </div>
          <div class="swiper-pagination-honor"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/swiper.min.css"
import imgUrl from "@/utils/imgUrl"
import Theme from "@/components/Theme.vue"
import arrJson from '@/utils/arrJson'

export default {
  name: 'Home',
  components: {
    "Theme": Theme, //公司概况
  },
  data() {
    return {
      isMobile: window.innerWidth < 780,
      imgUrl:imgUrl,
      intellectualList: arrJson.intellectualList,
      honorList: arrJson.honorList,
      overviewListLeft: [
        {
          img: imgUrl.overViewIcon1,
          title: '燃气安全',
          content: '通过阵列传感器实现多种气体的同时监测，并将数据实时上传至云端进行数据可视化处理。使用了功耗远低于市面上的金属氧化物气体传感器， 同时保持了媲美电化学气体传感器的高灵敏度。'
        },  {
          img: imgUrl.overViewIcon6,
          title: '智慧医疗',
          content: '智慧医疗设备利用传感器、数据分析、互联网和人工智能等技术，能够收集、分析和处理大量的医疗数据，为医生和患者提供更准确的诊断、治疗和监测手段。'
        },  {
          img: imgUrl.overViewIcon3,
          title: '智能家居',
          content: '客户定制化管理家庭气体监测，具有使用寿命长，体积小，灵敏度高，抗干扰力强，便携等优点的手机监测系统，保障用户的安全居住。'
        }
      ],
      overviewListRight: [
        {
          img: imgUrl.overViewIcon4,
          title: '汽车电子',
          content: '通过监测车内空气质量，监测特殊气体泄露问题，发出及时的告警或者提醒，保障出行人的安全。'
        },  {
          img: imgUrl.overViewIcon5,
          title: '食品安全',
          content: '通过智慧冰箱(食品识别和监控)和冷链运输过程中食品的气体质量，分析判断食物的安全问题。'
        },  {
          img: imgUrl.overViewIcon2,
          title: '环境监测',
          content: '恶臭在线检测系统，网格化微型空气监测站，智慧公交站，智能空气质量监测展示平台，微信小程序气体质量展示。'
        }
      ],
      isTitleMove: false,
      isHonorMove: false,
      testShow: false,
      height1: "",
      height2: "",
      swiper1: null,
      swiper2: null
    }
  },
  mounted(){
    const thisScreenWid = window.innerWidth
    window.addEventListener("scroll", this.moveWord, true)
    window.addEventListener("resize", this.initFunc)
    this.height1 = this.isMobile ? (thisScreenWid-40)/2/213*300+"px" : "485px"
    this.height2 = this.isMobile ? (thisScreenWid-40)/2/350*250+"px" : "246px"
    this.setSwiper()
  },
  destroyed() {
    window.removeEventListener("resize", this.initFunc)
    window.removeEventListener("scroll", this.moveWord)
  },
  methods: {
    setSwiper(){
      const t = this
      this.swiper1 = new Swiper('.swiper', {
        pagination: {
          el: '.swiper-pagination-intellectual',
          clickable: true,
        },
        // loop: true, // 是否循环，内容会两倍
        // autoplay: true, // 是否自动播放
        clickable: true,
        slidesPerView: t.isMobile ? 2 : 5
      })
      this.swiper2 = new Swiper('.swiper-honor', {
        // loop: true,
        // autoplay: true,
        clickable: true,
        slidesPerView: t.isMobile ? 1 : 3,
        pagination: {
          el: '.swiper-pagination-honor',
          clickable: true,
        },
      })
    },
    moveWord() {
      const currentScroll = window.scrollY;
      const thisScreenHei = window.screen.height
      if(currentScroll >= this.$refs.overviewRef.offsetTop - thisScreenHei + 350 ){
        this.testShow = true
      }
      if(currentScroll >= this.$refs.wordRef.offsetTop - thisScreenHei + 150 ){
        this.isTitleMove = true
      }
      if(currentScroll >= this.$refs.honorRef.offsetTop - thisScreenHei + 150 ){
        this.isHonorMove = true
      }
    },
    initFunc() {
      if(this.isMobile !== (window.innerWidth < 780)){
        this.isMobile = window.innerWidth < 780
        this.swiper1.params.slidesPerView = this.isMobile ? 2 : 5;
        this.swiper2.params.slidesPerView = this.isMobile ? 1 : 3;
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.moveWord, true)
  },
  props: {
    msg: String
  }
}
</script>


<style scoped lang="less">
  .overview{
    background: #f3f6fd;
  }
  .swiper{
    position: relative;
  }
  .honor-part{
    background: #edf1f4;
  }
  /deep/.swiper-pagination-bullets{
    .swiper-pagination-bullet{
      width: 12px;
      height: 12px;
      background: #0b55dc;
    }
  }
  .swipperItem .img-up-word{
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.673) 0%, rgba(0, 0, 0, 0.1)100%);
    .img-up-word-content{
      color: #fff;
      text-align: center;
    }
    .img-up-word-dec{
      text-align: right;
      color: #ffffffcc;
    }
  }
  .swipperItem:hover .img-up-word{
    display: block;
  }
  @media (min-width: 781px) and (max-width: 999px) {
      .overview{
        .overview-con-left{
          margin-bottom: 100px;
        }
        .overview-con-right{
          margin-top: 160px;
          margin-left: 0px !important;
          width: 100%;
        }
      }
    }
  // PC
  .main{
    .word-title{
      text-align: left;
      padding: 20px 0 0;
      transform: translateY(-40%);
      .word-big{
        line-height: 100px;
        font-size: 30px;
        font-weight: bold;
        text-align: center;
      }
      .word-small{
        color: #9b9b9b;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 3px;
      }
    }

    .overview{
      padding: 40px 0;
      .overview-title{
        font-size: 32px;
        line-height: 70px;
        color: #333;
        font-weight: bold;
        text-align: center;
      }
      .overview-dec{
        font-size: 16px;
        line-height: 24px;
        color: #758390;
        text-align: center;
      }
      .overview-content{
        background: url("../../assets/homeIcon/middle.png") no-repeat 50% 50%;
        margin: 30px auto 0;
        padding: 50px 0;
        max-width: 1000px;
        .overview-con-one{
          margin: 30px 0;
          .overview-con-word{
            display: inline-block;
            vertical-align: top;
            width: 200px;
            text-align: left;
            .overview-con-title{
              font-size: 22px;
              line-height: 40px;
              color: #333333;
            }
            .overview-con-con{
              font-size: 14px;
              line-height: 20px;
              color: #75818f;
            }
          }
          .overview-con-img-box{
            display: inline-block;
            position: relative;
          }
        }
        .overview-con-left{
          display: inline-block;
          transform: translateX(-10%);
          vertical-align: top;
          .overview-con-word{
            margin-left: 10px;
          }
          .overview-con-one:nth-child(1){
            .overview-con-word{
              width: 320px;
            }
          }
          .overview-con-one:nth-child(2){
            .overview-con-word{
              width: 260px;
            }
          }
          .overview-con-one:nth-child(3){
            .overview-con-word{
              width: 280px;
            }
          }
          .overview-con-one{
            text-align: left;
          }
          .overview-con-img{
            position: absolute;
            left: 25px;
            top: -18px;
            width: 56%;
          }
        }
        .overview-con-right{
          display: inline-block;
          margin-left: 180px;
          transform: translateX(10%);
          .overview-con-word{
            margin-right: 30px;
          }
          .overview-con-one{
            text-align: right;
          }
          .overview-con-img{
            position: absolute;
            right: 25px;
            top: -13px;
            width: 56%;
          }
        }
        .overviewMove{
          transform: translateX(0);
          transition: all 1s;
          -ms-transition: all 1s;
        }
      }
    }
    .honor-part{
      background: url('../../assets/banner/bg.jpg');
      background-size: 100% 100%;
    }
    .titleMove{
      transform: translateY(0);
      transition: all 1s;
      -ms-transition: all 1s;
    }
    /deep/ .el-card__body, .el-main{
      padding: 0;
    }
    .word-btn{
      background: rgb(39, 44, 55);
      border: none;
      color: #ffffffc9;
      margin: 24px 0;
    }

    .swipperItem{
      position: relative;
      text-align: left;
      padding: 4% 6%;
      cursor: default;
      .imgBox{
        border: 1px solid #a3a2a0;
        padding: 4px 6px;
        background: #fff;
        margin: 40px 0 0;
      }
      .honor-img{
        width: 100%;
        height: 218px;
      }
      .img-up-word{
        .img-up-word-content{
          margin: 203px 10px 0;
          font-size: 14px;
          text-align: right;
        }
        .img-up-word-dec{
          font-size: 14px;
          margin: 0 10px;
        }
      }
    }
    .swiper-pagination-intellectual{
      margin: 20px 0;
      text-align: center;
    }
    .swiper-pagination-honor{
      margin: 20px 0;
      text-align: center;
    }

    .el-carousel__item h3 {
      color: #475669;
      font-size: 14px;
      opacity: 0.75;
      line-height: 200px;
      margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n+1) {
      background-color: #d3dce6;
    }
    .bannerList{
      max-width: 1000px;
      margin: 0 auto;
      overflow: hidden;
    }
  }


//移动端
  .main-m{
    .bannerList{
      width: 100%;
      overflow: hidden;
    }
    .img-up-word{
      .img-up-word-content{
        margin: 5rem 0.2rem 0;
        font-size: 0.4rem;
        text-align: right;
      }
      .img-up-word-dec{
        font-size: 0.24rem;
        margin: 0 0.2rem;
      }
    }
    .word-title{
      text-align: center;
      padding: 0.2rem 0;
      .word-big{
        line-height: 1.2rem;
        font-size: 0.6rem;
        font-weight: 500;
      }
      .word-small{
        color: #9b9b9b;
        font-size: 1rem;
        line-height: 1.6rem;
        letter-spacing: 0.2rem;
      }
    }
    .overview{
      padding: 1rem 0;
      .overview-title{
        font-size: 0.58rem;
        line-height: 1.2rem;
        color: #333;
        text-align: center;
      }
      .overview-dec{
        font-size: 0.4rem;
        line-height: 1rem;
        width: 90%;
        margin: 0 auto;
        color: #758390;
      }
      .overview-content{
        .overview-middle-img{
          background: url("../../assets/homeIcon/middle_m.png") no-repeat 50% 50%;
          background-size: 40%;
          height: 4.6rem;
        }
        .overview-con-one{
          .overview-con-img-box{
            position: relative;
            margin: 1rem auto 0;
            width: 2rem;
            .overview-con-img-bg{
              width: 100%;
            }
            .overview-con-img{
              position: absolute;
              display: block;
              width: 1.1rem;
              top: -0.32rem;
              left: 0.44rem;
            }
          }
          .overview-con-word{
            padding: 0 0.4rem;
            .overview-con-title{
              font-size: 0.46rem;
              line-height: 1rem;
              color: #333;
              text-align: center;
            }
            .overview-con-con{
              font-size: 0.38rem;
              line-height: 0.7rem;
              color: #75818f;
              text-indent: 0.7rem;
            }
          }
        }
      }
    }
    .honor-part{
      background: url('../../assets/banner/bg.jpg');
      background-size: 100% 100%;
    }
    .swiper-pagination-intellectual{
      text-align: center;
    }
    .swiper-pagination-honor{
      text-align: center;
    }
    /deep/.swiper-pagination-bullet{
      width: 0.2rem;
      height: 0.2rem;
    }
    .swipperItem{
      padding: 4% 6%;
      .honor-img{
        width: 100%;
      }
      .honor-img-down{
        margin: 0.5rem 0 0;
      }
    }
  }
</style>
