
<template>
  <div :class="isMobile ? 'main-m' : 'main'" :style="'background: '+bg">
    <div class="theme">
    <div class="theme-title">专注于仿生嗅觉芯片研发和生产的国家高新技术企业</div>
    <div class="theme-dec">艾感科技（广东）有限公司是一家专注于仿生嗅觉芯片研发和生产的国家高新技术企业。
      公司于2018年12月在佛山成立，核心团队来源于香港科技大学知名教授课题组负责人及骨干。
      该课题组获得2020年国家半导体十大进展第一名，2021年粤港澳大湾区集成电路创新创业大赛一等奖，
      负责人获得2022年腾讯“科学探索奖”。
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'Detail',
  data() {
    return {
      isMobile: window.innerWidth < 780,
    }
  },
  props: {
    bg: String || "#fff"
  },
  mounted() {
    window.addEventListener("resize", this.initFunc)
  },
  methods: {
    initFunc() {
      this.isMobile = window.innerWidth < 780
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.initFunc)
  },
}
</script>


<style scoped lang="less">
  .main{
    .theme{
      max-width: 900px;
      padding: 40px 0 42px;
      margin: 0 auto;
      .theme-title{
        font-size: 24px;
        margin: 0 0 24px;
        color: #333;
        font-weight: bold;
        text-align: center;
      }
      .theme-dec{
        font-size: 16px;
        line-height: 24px;
        color: #738392;
        text-align: center;
      }
    }
  }
  .main-m{
    .theme{
      width: 90%;
      padding: 0.6rem 0;
      margin: 0 auto;
      .theme-title{
        line-height: 1rem;
        font-size: 0.5rem;
        margin: 0.2rem 0;
        color: #333;
        text-align: center;
      }
      .theme-dec{
        font-size: 0.4rem;
        line-height: 0.8rem;
        color: #738392;
        text-align: center;
      }
    }
  }
    

</style>
