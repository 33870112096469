
<template>
    <div>
      <div class="fix">
        <div>
          <el-popover
            placement="left"
            trigger="hover"
            popper-class="thisPopover">
            <div>
              <div>电话咨询</div>
              <div>13318386725</div>
              <img src="../assets/icon/triangle.png" class="triangle1" />
            </div>
            <div class="fix-one" slot="reference">
              <img :src="imgUrl.phone" />
            </div>
          </el-popover>
          <el-popover
            placement="left"
            trigger="hover"
            popper-class="thisPopover">
            <div>
              <div>二维码</div>
              <img :src="imgUrl.codePer" />
              <img src="../assets/icon/triangle.png" class="triangle2" />
            </div>
            <div class="fix-one" slot="reference">
              <img :src="imgUrl.code" />
            </div>
          </el-popover>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import imgUrl from "@/utils/imgUrl"

  export default {
    name: 'Detail',
    data() {
      return {
          isMobile: window.innerWidth < 780,
          imgUrl
      }
    },
    props: {
      msg: String
    }
  }
  </script>
  
  
  <style lang="less">
    .fix{
      .fix-one{
        background: #0032dd;
        padding: 10px 13px;
        border: 2px solid #51a9f3;
        margin-top: -2px;
      }
      .fix-one:hover{
        background: #fc0230;
        border: 2px solid #f3a4b7;
      }
      img{
        display: block;
      }
    }
    .thisPopover{
      min-width: 100px !important;
      text-align: center !important;
      border: 2px solid #fc0230 !important;
      border-radius: 8px !important;
      position: relative;
      .triangle1{
        position: absolute;
        right: -14px;
        top: 25px;
        width: 11%;
      }
      .triangle2{
        position: absolute;
        right: -14px;
        top: 63px;
        width: 11%;
      }
    }
  </style>
  