<template>
  <div :class="isMobile ? 'header-m' : 'header'">
    <div class="topNav">
      <el-row type="flex" justify="space-between">
        <el-col :span="isMobile ? 8 : 6" class="logo">
            <img src="./../assets/logo-blue.png" />
        </el-col>
        <el-col :span="19" class="menu">
            <span class="menuItem" v-for="(item) in menuList" :key="item.name">
              <router-link 
                :to="item.url" 
                :class="item.includeName?.includes(currentName) ? 'currentItem': ''"
              >{{item.name}}</router-link>
            </span>
        </el-col>
        <el-col :span="6" class="mobile-menu">
            <el-button icon="el-icon-s-unfold" @click="drawer = true"></el-button>
        </el-col>
      </el-row>
    </div>
    <div class="banner-home" v-if="currentName==='home'" @click="jumptoprod()">
    </div>
    <div class="banner-about" v-else-if="currentName==='about'">
    </div>
    <div class="banner-honor" v-else-if="currentName==='gain'">
    </div>
    <div class="banner-product" v-else-if="currentName==='product' || currentName==='productDetail'">
    </div>
    <div class="banner-news" v-else-if="currentName==='news'">
    </div>
    <div class="banner-join" v-else-if="currentName==='join'">
    </div>
    <div>
      <el-drawer
        title=""
        :visible.sync="drawer"
        size="50%"
        direction="rtl">
        <div class="mobile-menu-item">
          <span class="menuItem" v-for="(item) in menuList" :key="item.name" @click="clickmenu">
            <router-link 
              :to="item.url" 
              :class="item.includeName?.includes(currentName) ? 'currentItem': ''"
            >{{item.name}}</router-link>
          </span>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      isMobile: window.innerWidth < 780,
      drawer: false,
      currentName: this.$route.name,
      menuList: [
        {
          name: '首页',
          url: '/home'
        }, {
          name: '产品展示',
          url: '/product',
          includeName: ['productDetail', 'product']
        }, {
          name: '公司动态',
          url: '/news'
        }, {
          name: '关于我们',
          url: '/about'
        }, {
          name: '成果成就',
          url: '/gain'
        }, {
          name: '加入我们',
          url: '/join'
        }
      ]
    }
  },
  mounted() {
    window.addEventListener("resize", this.initFunc)
  },
  methods: {
    clickmenu() {
      this.drawer = false // 选择了菜单之后关闭抽屉
    },  
    initFunc() {
      this.isMobile = window.innerWidth < 780
    },
    jumptoprod() {
      window.sessionStorage.setItem("scroll", 100)
      this.$router.push('/product');
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.initFunc)
  },
  watch: {
    "$route.path" (to, from){
      this.currentName = this.$route.name
    }
  },
  props: {
    msg: String
  }
}
</script>


<style scoped lang="less">
  .header{
    position: relative;
    .topNav{
     max-width: 1000px;
     margin: 0 auto;
    }
    .banner-home{
      height: 436px;
      width: 100%;
      background: url('./../assets/banner/banner.jpg') no-repeat 0 0;
      background-position: center;
    }
    .banner-about{
      height: 436px;
      width: 100%;
      background: url('./../assets/banner/banner-about.jpg') no-repeat 0 0;
      background-position: center;
    }
    .banner-honor{
      height: 436px;
      width: 100%;
      background: url('./../assets/banner/banner-honor.jpg') no-repeat 0 0;
      background-position: center;
    }
    .banner-news{
      height: 436px;
      width: 100%;
      background: url('./../assets/banner/banner-news.jpg') no-repeat 0 0;
      background-position: center;
    }
    .banner-product{
      height: 436px;
      width: 100%;
      background: url('./../assets/banner/banner-project.jpg') no-repeat 0 0;
      background-position: center;
    }
    .banner-join{
      height: 436px;
      width: 100%;
      background: url('./../assets/banner/banner-join.jpg') no-repeat 0 0;
      background-position: center;
    }
    .logo {
      padding: 18px 2% 10px 4%;
      vertical-align: middle;
      img {
        width: 100%;
        display: block;
      }
    }
    .menu{
      vertical-align: middle;
      text-align: right;
      .menuItem{
        padding: 25px 3.2%;
        font-size: 17px;
        font-weight: bold;
        display: inline-block;
        a{
          color: #000000;
          text-decoration: none;
        }
        a:hover{
          color: #246bec;
        }
        /deep/ .router-link-active{
          color: #246bec;
        }
        .currentItem{
          color: #246bec;
        }
      }
    }
    .word{
      text-align: center;
      color: #fff;
      margin: 66px 0;
      .btn{
        background: rgb(39, 46, 55);
        border: none;
        color: #ffffffc9;
        margin: 20px;
      }
    }
  }
  .mobile-menu{
    display: none;
  }
  // @media (min-width: 781px) and (max-width: 999px) {
  //     .header{
  //       .banner-home{
  //         background: url('./../assets/banner/banner.jpg') no-repeat 0 0;
  //         background-size: 100% 100%;
  //         height: 340px;
  //       }
  //     }
  //   }

  // 移动端
  .header-m{
    text-align: left;
    .logo{
      margin: 0.2rem 0.2rem 0 0.5rem;
      img {
        width: 100%;
      }
    }
    .menu{
      display: none;
    }
    .banner-home{
      height: 3.8rem;
      width: 100%;
      background: url('./../assets/banner/banner-m.jpg') no-repeat center center;
      background-size: 100% 100%;
    }
    .banner-about{
      height: 3.6rem;
      width: 100%;
      background: url('./../assets/banner/banner-about-m.jpg') no-repeat center center;
      background-size: 100% 100%;
    }
    .banner-honor{
      height: 3.6rem;
      width: 100%;
      background: url('./../assets/banner/banner-honor-m.jpg') no-repeat center center;
      background-size: 100% 100%;
    }
    .banner-product{
      height: 3.6rem;
      width: 100%;
      background: url('./../assets/banner/banner-project-m.jpg') no-repeat center center;
      background-size: 100% 100%;
    }
    .banner-news{
      height: 3.6rem;
      width: 100%;
      background: url('./../assets/banner/banner-news-m.jpg') no-repeat center center;
      background-size: 100% 100%;
    }
    .banner-join{
      height: 3.6rem;
      width: 100%;
      background: url('./../assets/banner/banner-join-m.jpg') no-repeat center center;
      background-size: 100% 100%;
    }
    .mobile-menu{
      display: block;
      position: absolute;
      right: 0.3rem;
      top: 0rem;
      text-align: right;
      .el-button{
        background: none;
        border-color: #028eff;
        color: #028eff;
        font-size: 0.6rem;
        padding: 0.1rem 0.16rem;
      }
      a{
        color: #ffffffac;
        text-decoration: none;
      }
    }
    .mobile-menu-item{
      .menuItem{
        display: block;
        text-align: left;
        margin-left: 0.4rem;
        margin-right: 0.2rem;
        a{
          text-decoration: none;
          color: #ffffffb8;
          font-size: 0.4rem;
          line-height: 1.2rem;
          border-left: 0.14rem solid #0b55dc;
          border-bottom: 0.01rem solid #1c69f5;
          display: block;
          padding: 0 1rem;
        }
        .router-link-active{
          color: #fff;
          border-left: 0.08rem solid #a9d5fa;
        }
        .currentItem{
          color: #fff;
          border-left: 0.08rem solid #a9d5fa;
        }
      }
    }
  }
  /deep/ .el-drawer{
    background: #0b55dc;
  }
  /deep/ .el-drawer__close-btn{
    font-size: 0.6rem;
    color: #e5f3ffa0;
  }
  /deep/ .el-drawer__header{
    padding: 0.4rem 0 0;
  }
</style>
