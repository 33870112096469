//判断空气质量
  const getQuality = function(obj, type){
    var quality = "";
    obj = parseFloat(obj);
    switch (type) {
      case "SO2":
        quality = getSO2Quality(obj);
        break;
      case "NO2":
        quality = getNO2Quality(obj);
        break;
      case "PM10":
        quality = getPM10Quality(obj);
        break;
      case "PM25":
        quality = getPM25Quality(obj);
        break;
      case "CO":
        quality = getCOQuality(obj);
        break;
      case "O3":
        quality = getO3Quality(obj);
        break;
      default:
        break;
    }
    return quality;
  }
  const getSO2Quality = function(obj) {
    var quality = "",
        bgStyle = "",
        color = "";
    switch (true) {
      case (obj > 0 && obj <= 150):
        quality = "优";
        bgStyle = "bg-green";
        color = "#39b54a";
        break;
      case obj > 150 && obj <= 500:
        quality = "良";
        bgStyle = "bg-olive";
        color = "#8dc63f";
        break;
      case obj > 500 && obj <= 650:
        quality = "轻";
        bgStyle = "bg-yellow";
        color = "#fbbd08";
        break;
      case obj > 650 && obj <= 800:
        quality = "中";
        bgStyle = "bg-orange";
        color = "#f37b1d";
        break;
      case obj > 800 && obj <= 1600:
        quality = "重";
        bgStyle = "bg-red";
        color = "#e54d42";
        break;
      case obj > 1600:
        quality = "严";
        bgStyle = "bg-purple";
        color = "#6739b6";
        break;
      default:
        quality = "异";
        bgStyle = "bg-red";
        color = "#e54d42";
        break;
    }
    return {quality,bgStyle,color};
  }
  const getNO2Quality = function(obj) {
    var quality = "",
        bgStyle = "",
        color = "";
    switch (true) {
      case (obj > 0 && obj <= 100):
        quality = "优";
        bgStyle = "bg-green";
        color = "#39b54a";
        break;
      case obj > 100 && obj <= 200:
        quality = "良";
        bgStyle = "bg-olive";
        color = "#8dc63f";
        break;
      case obj > 200 && obj <= 700:
        quality = "轻";
        bgStyle = "bg-yellow";
        color = "#fbbd08";
        break;
      case obj > 700 && obj <= 1200:
        quality = "中";
        bgStyle = "bg-orange";
        color = "#f37b1d";
        break;
      case obj > 1200 && obj <= 2340:
        quality = "重";
        bgStyle = "bg-red";
        color = "#e54d42";
        break;
      case obj > 2340:
        quality = "严";
        bgStyle = "bg-purple";
        color = "#6739b6";
        break;
      default:
        quality = "异";
        bgStyle = "bg-red";
        color = "#e54d42";
        break;
    }
    return {quality,bgStyle,color};
  }
  const getPM10Quality = function(obj) {
    var quality = "",
        bgStyle = "",
        color = "";
    switch (true) {
      case (obj > 0 && obj <= 50):
        quality = "优";
        bgStyle = "bg-green";
        color = "#39b54a";
        break;
      case obj > 50 && obj <= 150:
        quality = "良";
        bgStyle = "bg-olive";
        color = "#8dc63f";
        break;
      case obj > 150 && obj <= 250:
        quality = "轻";
        bgStyle = "bg-yellow";
        color = "#fbbd08";
        break;
      case obj > 250 && obj <= 350:
        quality = "中";
        bgStyle = "bg-orange";
        color = "#f37b1d";
        break;
      case obj > 350 && obj <= 420:
        quality = "重";
        bgStyle = "bg-red";
        color = "#e54d42";
        break;
      case obj > 420:
        quality = "严";
        bgStyle = "bg-purple";
        color = "#6739b6";
        break;
      default:
        quality = "异";
        bgStyle = "bg-red";
        color = "#e54d42";
        break;
    }
    return {quality,bgStyle,color};
  }
  const getPM25Quality = function(obj) {
    var quality = "",
        bgStyle = "",
        color = "";
    switch (true) {
      case (obj > 0 && obj <= 35):
        quality = "优";
        bgStyle = "bg-green";
        color = "#39b54a";
        break;
      case obj > 35 && obj <= 75:
        quality = "良";
        bgStyle = "bg-olive";
        color = "#8dc63f";
        break;
      case obj > 75 && obj <= 115:
        quality = "轻";
        bgStyle = "bg-yellow";
        color = "#fbbd08";
        break;
      case obj > 115 && obj <= 150:
        quality = "中";
        bgStyle = "bg-orange";
        color = "#f37b1d";
        break;
      case obj > 150 && obj <= 250:
        quality = "重";
        bgStyle = "bg-red";
        color = "#e54d42";
        break;
      case obj > 250:
        quality = "严";
        bgStyle = "bg-purple";
        color = "#6739b6";
        break;
      default:
        quality = "异";
        bgStyle = "bg-red";
        color = "#e54d42";
        break;
    }
    return {quality,bgStyle,color};
  } 
  const getCOQuality = function(obj) {
    var quality = "",
        bgStyle = "",
        color = "";
    switch (true) {
      case (obj > 0 && obj <= 5):
        quality = "优";
        bgStyle = "bg-green";
        color = "#39b54a";
        break;
      case obj > 5 && obj <= 10:
        quality = "良";
        bgStyle = "bg-olive";
        color = "#8dc63f";
        break;
      case obj > 10 && obj <= 35:
        quality = "轻";
        bgStyle = "bg-yellow";
        color = "#fbbd08";
        break;
      case obj > 35 && obj <= 60:
        quality = "中";
        bgStyle = "bg-orange";
        color = "#f37b1d";
        break;
      case obj > 60 && obj < 90:
        quality = "重";
        bgStyle = "bg-red";
        color = "#e54d42";
        break;
      case obj > 90:
        quality = "严";
        bgStyle = "bg-purple";
        color = "#6739b6";
        break;
      default:
        quality = "异";
        bgStyle = "bg-red";
        color = "#e54d42";
        break;
    }
    return {quality,bgStyle,color};
  }
  const getO3Quality = function(obj) {
    var quality = "",
        bgStyle = "",
        color = "";
    switch (true) {
      case (obj > 0 && obj <= 160):
        quality = "优";
        bgStyle = "bg-green";
        color = "#39b54a";
        break;
      case obj > 160 && obj <= 200:
        quality = "良";
        bgStyle = "bg-olive";
        color = "#8dc63f";
        break;
      case obj > 200 && obj <= 300:
        quality = "轻";
        bgStyle = "bg-yellow";
        color = "#fbbd08";
        break;
      case obj > 300 && obj <= 400:
        quality = "中";
        bgStyle = "bg-orange";
        color = "#f37b1d";
        break;
      case obj > 400 && obj < 800:
        quality = "重";
        bgStyle = "bg-red";
        color = "#e54d42";
        break;
      case obj > 800:
        quality = "严";
        bgStyle = "bg-purple";
        color = "#6739b6";
        break;
      default:
        quality = "异";
        bgStyle = "bg-red";
        color = "#e54d42";
        break;
    }
    return {quality,bgStyle,color};
  }
  //判断是否维护
  const getStatus = function(obj) {
    var s1 = new Date().getTime();
    var s2 = new Date(obj).getTime();
    var total = s1 - s2;
    var min = Math.floor(total / 1000 / 60);
    if (min > 30) {
      return "维护";
    } else {
      return "在线";
    }
  }
  //判断风向
  const getDirection = function(obj) {
    switch (true) {
      case (obj > -3 && obj <= 3) || (obj > 357 && obj <= 363):
        obj = "正北";
        break;
      case obj > 3 && obj <= 87:
        obj = "东北";
        break;
      case obj > 87 && obj <= 93:
        obj = "正东";
        break;
      case obj > 93 && obj <= 177:
        obj = "东南";
        break;
      case obj > 177 && obj < 183:
        obj = "正南";
        break;
      case obj > 183 && obj <= 267:
        obj = "西南";
        break;
      case obj > 267 && obj <= 273:
        obj = "正西";
        break;
      case obj > 273 && obj <= 357:
        obj = "西北";
        break;
      default:
        obj = "异";
        break;
    }
    return obj;
  }
  const getAirTypeName = function(obj){
    var typeName = "";
    switch (obj) {
      case 1:
        typeName = "SO2"
        break;
      case 2:
        typeName = "NO2"
        break;
      case 3:
        typeName = "PM10"
        break;
      case 4:
        typeName = "PM25"
        break;
      case 7:
        typeName = "CO"
        break;
      case 8:
        typeName = "O3"
        break;
      default:
        break;
    }
    return typeName;
  }
  const isMobileNumber = function(number) {
    var reg = /^1\d{10}$/;
    return reg.test(number);
  }

module.exports = {
    getQuality,
    getSO2Quality,
    getNO2Quality,
    getPM10Quality,
    getPM25Quality,
    getCOQuality,
    getO3Quality,
    getStatus,
    getDirection,
    getAirTypeName,
    isMobileNumber,
}