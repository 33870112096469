<template>
    <div :class="isMobile ? 'gain-m' : 'gain'">
      <div class="intellectual">
        <div class="word">
          <div class="word-title">知识产权</div>
          <div class="word-gray"></div>
          <div class="word-text">INTELLECTUAL PROPERTY</div>
        </div>
        <div class="intellectual-content">
          <div class="intellectual-list">
            <div class="intellectual-one" v-for="(item, index) in intellectualList" :key="item.text">
              <div class="word">
                <span class="intellectual-word-big">{{item.num}}</span>
                <span class="intellectual-word-small">{{item.unit}}</span>
                <div class="intellectual-word-text">{{item.text}}</div>
              </div> 
              <div 
                v-show="(!isMobile && index!==(intellectualList.length-1)) 
                        || (isMobile && index!==(intellectualList.length-1) && ((index+1)%3!==0))" 
                class="gray"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="standard">
        <div class="word">
          <div class="word-title">标准</div>
          <div class="word-gray"></div>
          <div class="word-text">STANDARD</div>
        </div>
        <div class="standard-content">
          <div class="standard-content-one" v-for="(item) in standList" :key="item.text">
            <div class="leftImg"></div>
            <div class="word">
              <span class="standard-word-num">{{item.num}}</span>
              <span class="standard-word-unit">{{item.unit}}</span>
              <div  class="standard-word-text">{{item.text}}</div>
            </div>
            <div class="rightImg"></div>
          </div>
        </div>
      </div>
      <div class="sci">
        <div class="sci-content"> 
          <span class="sci-title">论文SCI10篇</span>
          <span class="sci-text">10 SCI PAPERS</span>
        </div>
      </div>
      <div class="standard">
        <div class="word">
          <div class="word-title">服务机构</div>
          <div class="word-gray"></div>
          <div class="word-text">SERVICE ORGANIZATION</div>
        </div>
        <div class="standard-content">
          <div class="standard-content-one" v-for="(item) in serverList" :key="item.text">
            <div class="leftImg"></div>
            <div class="word">
              <span class="standard-word-num">{{item.num}}</span>
              <span class="standard-word-unit">{{item.unit}}</span>
              <div  class="standard-word-text">{{item.text}}</div>
            </div>
            <div class="rightImg"></div>
          </div>
        </div>
      </div>
      <div class="area">
        <div class="word">
          <div class="word-title">应用领域</div>
          <div class="word-gray"></div>
          <div class="word-text">APPLICATION AREA</div>
        </div>
        <div class="area-content"> 
          <div v-for="(item) in areaList" :key="item.text" class="area-one">
            <div class="area-one-img">
              <img :src="item.img" />
            </div>
            <div class="area-one-text">{{ item.text }}</div>
          </div>
        </div>
      </div>
      <Theme bg="#f3f4f9"></Theme>
    </div>
  </template>
  
  <script>
  import Theme from "@/components/Theme.vue"
  import imgUrl from "@/utils/imgUrl";
  
  export default {
    name: 'Gain',
    components: {
      "Theme": Theme, //公司概况
    },
    data() {
      return{
        isMobile: window.innerWidth < 780,
        intellectualList: [
          {
            num: 65,
            unit: "件",
            text: "发明专利"
          }, {
            num: 33,
            unit: "件",
            text: "授权专利"
          },{
            num: 23,
            unit: "项",
            text: "商标"
          }, {
            num: 11,
            unit: "项",
            text: "软著"
          },{
            num: 2,
            unit: "件",
            text: "外观专利"
          },{
            num: 2,
            unit: "件",
            text: "国际专利(PCT)"
          },{
            num: 3,
            unit: "件",
            text: "实用新型"
          }
        ],
        standList: [
          {
            num: 2,
            unit: "项",
            text: "企标"
          }, {
            num: 1,
            unit: "项",
            text: "国标"
          }, {
            num: 3,
            unit: "项",
            text: "团标"
          }
        ],
        serverList: [
          {
            num: 26,
            unit: "家",
            text: "服务企业"
          }, {
            num: 4,
            unit: "家",
            text: "学术机构"
          }, {
            num: 1,
            unit: "个",
            text: "政府部门"
          }
        ],
        areaList: [
          {
            text: "室内空气监测",
            img: imgUrl.area1
          }, {
            text: "消防监测",
            img: imgUrl.area2
          }, {
            text: "汽车电子",
            img: imgUrl.area3
          }, {
            text: "安全监控",
            img: imgUrl.area4
          }, {
            text: "空气净化器",
            img: imgUrl.area5
          }, {
            text: "环保监测",
            img: imgUrl.area6
          }, {
            text: "智慧城市",
            img: imgUrl.area7
          }, {
            text: "气味数字化",
            img: imgUrl.area8
          }
        ]
      }
    },
    props: {
      msg: String
    },
    mounted() {
      window.addEventListener("resize", this.initFunc)
    },
    methods: {
      initFunc() {
        this.isMobile = window.innerWidth < 780
      }
    },
    destroyed() {
      window.removeEventListener("resize", this.initFunc)
    },
  }
  </script>
  
  
  <style scoped lang="less">
    .gain{
      .word{
        .word-title{
          font-size: 26px;
          line-height: 36px;
          font-weight: bold;
          color: #333333;
          letter-spacing: 2px;
          margin: 0 0 10px;
          text-align: center;
        }
        .word-gray{
          width: 80px;
          height: 2px;
          background: #d6d6d6;
          margin: 0 auto;
        }
        .word-text{
          font-size: 16px;
          line-height: 36px;
          color: #758292 ;
          text-align: center;
        }
      }
      .intellectual{
        padding: 60px 0 0;
        .intellectual-content{
          padding: 74px 0 76px;
          max-width: 1100px;
          margin: 0 auto;
          .intellectual-list{
            .intellectual-one{
              text-align: center;
              display: inline-block;
              width: 14%;
              .word{
                display: inline-block;
                .intellectual-word-big{
                  color: #171717;
                  font-size: 34px;
                  font-weight: bold;
                }
                .intellectual-word-small{
                  color: #161616;
                  font-size: 16px;
                }
                .intellectual-word-text{
                  color: #161616;
                  font-size: 16px;
                }
              }
              .gray{
                background: #d7d3d4;
                width: 2px;
                height: 48px;
                float: right;
                margin-top: 12px;
              }
            }
          }
        }
      }
      .standard{
        // background: url("../../assets/banner/bg.jpg") no-repeat;
        background-size: 100%;
        padding: 50px 0 80px;
        background-color: #f3f4f9;
        .standard-content{
          max-width: 1150px;
          margin: 0 auto;
          .standard-content-one{
            width: 33%;
            display: inline-block;
            text-align: center;
            color: #0786f9;
            margin-top: 50px;
            .leftImg{
              display: inline-block;
              vertical-align: middle;
              background: url("../../assets/icon/leaf.png") no-repeat;
              width: 35px;
              height: 88px;
              transform: scaleX(-1);
              -webkit-transform: scaleX(-1); /* 兼容老版本 WebKit 浏览器 */
              -ms-transform: scaleX(-1);
            }
            .rightImg{
              display: inline-block;
              vertical-align: middle;
              background: url("../../assets/icon/leaf.png") no-repeat;
              width: 35px;
              height: 88px;
            }
            .word{
              display: inline-block;
              vertical-align: middle;
              margin: 0 10%;
              .standard-word-num{
                font-size: 30px;
                font-weight: bold;
              }
              .standard-word-unit{
                font-size: 30px;
              }
              .standard-word-text{
                font-size: 20px;
              }
            }
          }
        }
      }
      .sci{
        background: url("./../../assets/banner/bg-blue.jpg") no-repeat;
        background-size: 100% 100%;
        padding: 40px 0;
        .sci-content{
          max-width: 1000px;
          margin: 0 auto;
          .sci-title{
            color: #fff;
            font-size: 58px;
            font-weight: bold;
            margin-right: 30px;
            font-family: auto;
          }
          .sci-text{
            color: #0e5190;
            font-size: 36px;
          }
        }

      }
      .area{
        padding: 40px 0 46px;
        .area-content{
          max-width: 1000px;
          margin: 20px auto;
          .area-one{
            display: inline-block;
            width: 12.5%;
            text-align: center;
            .area-one-img{
              background: #0683f7;
              display: inline-block;
              border-radius: 50%;
              width: 90px;
              height: 90px;
              text-align: center;
              img{
                margin-top: 16px;
              }
            }
            .area-one-text{
              font-size: 15px;
              color: #1d1d1d;
              font-weight: bold;
              margin-top: 20px;
            }
          }
        }
      }
    }
    .gain-m{
      .word{
        .word-title{
          font-size: 0.6rem;
          line-height: 0.8rem;
          font-weight: bold;
          color: #333333;
          letter-spacing: 0.05rem;
          margin: 0 0 0.2rem;
          text-align: center;
        }
        .word-gray{
          width: 1.7rem;
          height: 0.05rem;
          background: #d6d6d6;
          margin: 0 auto;
        }
        .word-text{
          font-size: 0.32rem;
          line-height: 0.8rem;
          color: #758292 ;
          text-align: center;
        }
      }
      .intellectual{
        padding: 1rem 0 0;
        .intellectual-content{
          padding: 1rem 0 1.5rem;
          margin: 0 0.1rem;
          text-align: center;
          .intellectual-list{
            .intellectual-one{
              text-align: center;
              display: inline-block;
              width: 33%;
              .word{
                display: inline-block;
                .intellectual-word-big{
                  color: #171717;
                  font-size: 0.7rem;
                  font-weight: bold;
                }
                .intellectual-word-small{
                  color: #161616;
                  font-size: 0.36rem;
                }
                .intellectual-word-text{
                  color: #161616;
                  font-size: 0.34rem;
                }
              }
              .gray{
                background: #d7d3d4;
                width: 0.04rem;
                height: 1.2rem;
                float: right;
                margin-top: 0.6rem;
              }
            }
          }
        }
      }
      .standard{
        // background: url("../../assets/banner/bg.jpg") no-repeat;
        // background-size: 100%;
        padding: 1rem 0 1.8rem;
        background-color: #f3f4f9;
        .standard-content{
          margin: 0 0.1rem;
          text-align: center;
          .standard-content-one{
            width: 33%;
            display: inline-block;
            text-align: center;
            color: #0786f9;
            margin-top: 0.6rem;
            .leftImg{
              display: inline-block;
              vertical-align: middle;
              background: url("../../assets/icon/leaf.png") no-repeat;
              background-size: 100% 100%;
              width: 0.7rem;
              height: 1.7rem;
              transform: scaleX(-1);
              -webkit-transform: scaleX(-1); /* 兼容老版本 WebKit 浏览器 */
              -ms-transform: scaleX(-1);
              margin-top: 0.4rem;
            }
            .rightImg{
              display: inline-block;
              vertical-align: middle;
              background: url("../../assets/icon/leaf.png") no-repeat;
              background-size: 100% 100%;
              width: 0.6rem;
              height: 1.7rem;
              margin-top: 0.4rem;
            }
            .word{
              display: inline-block;
              vertical-align: middle;
              margin: 0 5%;
              .standard-word-num{
                font-size: 0.6rem;
                font-weight: bold;
              }
              .standard-word-unit{
                font-size: 0.6rem;
              }
              .standard-word-text{
                font-size: 0.36rem
              }
            }
          }
        }
      }
      .sci{
        background: url("./../../assets/banner/bg-blue.jpg") no-repeat;
        background-size: 100% 100%;
        padding: 0.7rem 0;
        .sci-content{
          margin: 0 0.5rem;
          .sci-title{
            color: #fff;
            font-size: 1rem;
            font-weight: bold;
            display: block;
          }
          .sci-text{
            color: #094782;
            font-size: 0.6rem;
            display: block;
          }
        }

      }
      .area{
        padding: 0.8rem 0 0.6rem;
        .area-content{
          margin: 0.4rem 0.2rem;
          .area-one{
            display: inline-block;
            width: 25%;
            text-align: center;
            .area-one-img{
              background: #0683f7;
              display: inline-block;
              border-radius: 50%;
              width: 1.6rem;
              height: 1.6rem;
              text-align: center;
              img{
                display: block;
                margin: 0.3rem auto;
                width: 50%;
              }
            }
            .area-one-text{
              font-size: 0.36rem;
              color: #1d1d1d;
              font-weight: bold;
              margin-top: 0.1rem;
              margin-bottom: 0.4rem;
            }
          }
        }
      }
    }
  </style>
  