<template>
  <div class="hello">
   this is detail page.
   <el-button>默认按钮</el-button>
  </div>
</template>

<script>
export default {
  name: 'Detail',
  props: {
    msg: String
  }
}
</script>


<style scoped>

</style>
