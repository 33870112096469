import Vue from 'vue'
import App from './App.vue'
import router from "./router"
import VueRouter from 'vue-router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './vue'
import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'
import { Notify, Dialog } from 'vant';
import 'vant/lib/notify/style'
import 'vant/lib/dialog/style'

// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from "echarts/core";
// 引入柱状图图表，图表后缀都为 Chart
import { LineChart, PieChart, BarChart } from "echarts/charts";
// 引入提示框，标题，直角坐标系组件，组件后缀都为 Component
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  MarkLineComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent
} from "echarts/components";
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";
// 注册必须的组件
echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  MarkLineComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  PieChart,
  BarChart,
  CanvasRenderer,
  DataZoomComponent
]);
Vue.prototype.$echarts = echarts;

Vue.use(VueRouter)
Vue.use(ElementUI, {})
Vue.use(Notify)
Vue.use(Dialog)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router: router
}).$mount('#app')
