<template>
  <div :class="isMobile ? 'productDetail-m' : 'productDetail'">
    <el-breadcrumb separator="/" class="bread">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/product' }">产品</el-breadcrumb-item>
      <el-breadcrumb-item>产品详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row class="part" v-if="isShow">
      <el-col :span="isMobile ? 24 : 8" class="imgPart">
        <img class="bigImg" :src="require(`./../../assets/project/${thisObj.imgUrl}`)" />
      </el-col>
      <el-col :span="isMobile ? 22 : 14" :offset="isMobile ? 0 : 2">
        <div>
          <div class="title">{{thisObj.name}}</div>
          <div class="text" v-for="(item, index) in thisObj.text" :key="index">{{item}}</div>
          <a v-show="thisObj.href" :href="thisObj.href" target="_blank" class="toPdf">点击查看>></a>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import arrJson from "../../utils/arrJson"
export default {
  name: 'ProdectDetail',
  data() {
    return {
      isMobile: window.innerWidth < 780,
      id: null,
      isShow: false,
      thisObj: {}
    }
  },
  mounted() {
    this.getDetail()
    window.addEventListener("resize", this.initFunc)
  },
  destroyed() {
    window.removeEventListener("resize", this.initFunc)
  },
  methods: {
    getDetail() {
      let parentId = null
      const pathList = this.$router.currentRoute.path.split("/") // 取url上的id
      this.id = pathList[pathList.length-1]
      parentId = this.id.split("")[1] //取父级index，“012”，父级为“1”
      const index = arrJson.productList[parentId].data.findIndex(item => item.id+""===this.id+"")
      this.thisObj = arrJson.productList[parentId].data[index]
      this.isShow = true
    },
    initFunc() {
      this.isMobile = window.innerWidth < 780
    }
  },
  props: {
    msg: String
  }
}
</script>


<style scoped lang="less">
  /deep/ .el-breadcrumb__inner.is-link{
    font-weight: normal;
  }
  .productDetail{
    max-width: 1100px;
    margin: 0 auto 60px;
    .bread{
      line-height: 50px;
      margin-top: 20px;
    }
    .part{
      text-align: left;
      .imgPart{
        background: #d6dde8;
        text-align: center;
      }
      .bigImg{
        max-width: 100%;
        max-height: 400px;
        display: block;
        margin: 0 auto;
      }
      .title{
        font-size: 26px;
        line-height: 40px;
      }
      .text{
        font-size: 16px;
        line-height: 30px;
        text-align: left;
        color: #6f6f6f;
        margin-top: 40px;
        text-indent: 32px;
      }
      .toPdf{
        margin: 20px 0;
        display: block;
        color: #0089fe;
      }
    }
  }
  .productDetail-m{
    margin: 0 0.4rem 1rem;
    /deep/ .el-breadcrumb{
      font-size: 0.4rem;
    }
    .bread{
      line-height: 1.2rem;
    }
    .part{
      text-align: left;
      .imgPart{
        padding: 0.1rem;
        background: #d6dde8;
        text-align: center;
      }
      .bigImg{
        max-width: 90%;
        max-height: 10rem;
        display: block;
        margin: 0 auto;
      }
      .title{
        font-size: 0.46rem;
        line-height: 0.9rem;
        margin-top: 0.4rem;
      }
      .text{
        font-size: 0.38rem;
        line-height: 0.7rem;
        text-align: left;
        color: #6f6f6f;
        margin-top: 0.6rem;
        text-indent: 0.7rem;
      }
      .toPdf{
        margin: 0.46rem 0;
        display: block;
        color: #0089fe;
        font-size: 0.4rem;
      }
    }
  }


</style>