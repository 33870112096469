<template>
  <div>
    <div class="map-box">
      <div id="map" style="height: 10rem;" class="tMapBox"></div>
      <div class="legend-list">
        <span class="legend-one legend-a">优</span>
        <span class="legend-one legend-b">良</span>
        <span class="legend-one legend-c">轻度</span>
        <span class="legend-one legend-d">中度</span>
        <span class="legend-one legend-e">重度/维护</span>
        <span class="legend-one legend-f">严重</span>
      </div>
      <div class="updateTime">更新时间：{{ updateTime }}</div>
    </div>
    <div class="five-minutes">
      <div class="five-minutes-title">
        <span>5分钟平均数预警</span>
        <span class="subtitle">最近10分钟</span>
      </div>
      <div class="five-minutes-table">
        <table>
          <tr>
            <th>站点名称</th>
            <th>时间</th>
            <th>污染物</th>
            <th>污染值</th>
            <th>等级</th>
          </tr>
          <template v-if="fiveMinList.length>0">
            <tr v-for="(item, i) in fiveMinList" @click="jumpToDetail(item)">
              <td>{{item.macAddress}}</td>
              <td>{{item.beginTime}}</td>
              <td>{{item.airType}}</td>
              <td>{{item.airValue}}</td>
              <td>{{item.quality.quality}}</td>
            </tr>
          </template>
          <tr v-else>
            <td colspan="5"><div class="nodata green-text">空气质量优/良</div></td>
          </tr>
        </table>
      </div>
    </div>
    <div class="five-minutes">
      <div class="five-minutes-title">
        <span>实时预警</span>
        <span class="subtitle">30秒刷新一次</span>
      </div>
      <div class="five-minutes-subtitle">
        异常站点
      </div>
      <div class="other-table">
        <table>
          <tr>
            <th>站点名称</th>
            <th>环境温度</th>
            <th>环境湿度</th>
            <th>状态</th>
          </tr>
          <template v-if="allDetailDataList.stationQuesion.length>0">
            <tr v-for="(item, i) in allDetailDataList.stationQuesion"  @click="jumpToDetail(item)">
              <td>{{item.deviceName}}</td>
              <td :class="item.temp==0 ? 'red-text': ''">{{item.temp??'/'}}</td>
              <td :class="item.humidity==0 ? 'red-text': ''">{{item.humidity??'/'}}</td>
              <td :class="item.status=='维护' ? 'red-text': ''">{{item.status}}</td>
            </tr>
          </template>
          <tr v-else>
            <td colspan="4"><div class="nodata green-text">无异常站点</div></td>
          </tr>
        </table>
        
      </div>
      <div class="five-minutes-subtitle">
        SO₂污染预警（ug/m³）
      </div>
      <div class="other-table">
        <table>
          <tr>
            <th>站点名称</th>
            <th>时间</th>
            <th>温度</th>
            <th>污染物</th>
            <th>等级</th>
          </tr>
          <template v-if="allDetailDataList.so2Quesion.length>0">
            <tr v-for="(item, i) in allDetailDataList.so2Quesion"  @click="jumpToDetail(item)">
              <td>{{item.deviceName}}</td>
              <td>{{item.beginTime}}</td>
              <td :class="item.temp==0 ? 'red-text': ''">{{item.temp}}</td>
              <td>{{item.SO2}}</td>
              <td :style="'color:'+item.o3Quality.color">{{item.so2Quality.quality}}</td>
            </tr>
          </template>
          <tr v-else>
            <td colspan="5"><div class="nodata green-text">SO₂质量优/良</div></td>
          </tr>
        </table>
      </div>
      <div class="five-minutes-subtitle">
        NO₂污染预警（ug/m³）
      </div>
      <div class="other-table">
        <table>
          <tr>
            <th>站点名称</th>
            <th>时间</th>
            <th>温度</th>
            <th>污染物</th>
            <th>等级</th>
          </tr>
          <template v-if="allDetailDataList.no2Quesion.length>0">
            <tr v-for="(item, i) in allDetailDataList.no2Quesion" @click="jumpToDetail(item)">
              <td>{{item.deviceName}}</td>
              <td>{{item.beginTime}}</td>
              <td :class="item.temp==0 ? 'red-text': ''">{{item.temp}}</td>
              <td>{{item.NO2}}</td>
              <td :style="'color:'+item.o3Quality.color">{{item.no2Quality.quality}}</td>
            </tr>
          </template>
          <tr v-else>
            <td colspan="5"><div class="nodata green-text">NO₂质量优/良</div></td>
          </tr>
        </table>
      </div>
      <div class="five-minutes-subtitle">
        PM₁₀污染预警（ug/m³）
      </div>
      <div class="other-table">
        <table>
          <tr>
            <th>站点名称</th>
            <th>时间</th>
            <th>温度</th>
            <th>污染物</th>
            <th>等级</th>
          </tr>
          <template v-if="allDetailDataList.pm10Quesion.length>0">
            <tr v-for="(item, i) in allDetailDataList.pm10Quesion" @click="jumpToDetail(item)">
              <td>{{item.deviceName}}</td>
              <td>{{item.beginTime}}</td>
              <td :class="item.temp==0 ? 'red-text': ''">{{item.temp}}</td>
              <td>{{item.PM10}}</td>
              <td :style="'color:'+item.o3Quality.color">{{item.pm10Quality.quality}}</td>
            </tr>
          </template>
          <tr v-else>
            <td colspan="5"><div class="nodata green-text">PM₁₀质量优/良</div></td>
          </tr>
        </table>
      </div>
      <div class="five-minutes-subtitle">
        PM₂.₅污染预警（ug/m³）
      </div>
      <div class="other-table">
        <table>
          <tr>
            <th>站点名称</th>
            <th>时间</th>
            <th>温度</th>
            <th>污染物</th>
            <th>等级</th>
          </tr>
          <template v-if="allDetailDataList.pm25Quesion.length>0">
            <tr v-for="(item, i) in allDetailDataList.pm25Quesion" @click="jumpToDetail(item)">
              <td>{{item.deviceName}}</td>
              <td>{{item.beginTime}}</td>
              <td :class="item.temp==0 ? 'red-text': ''">{{item.temp}}</td>
              <td>{{item.PM25}}</td>
              <td :style="'color:'+item.o3Quality.color">{{item.pm25Quality.quality}}</td>
            </tr>
          </template>
          <tr v-else>
            <td colspan="5"><div class="nodata green-text">PM₂.₅质量优/良</div></td>
          </tr>
        </table>
      </div>
      <div class="five-minutes-subtitle">
        CO污染预警（ug/m³）
      </div>
      <div class="other-table">
        <table>
          <tr>
            <th>站点名称</th>
            <th>时间</th>
            <th>温度</th>
            <th>污染物</th>
            <th>等级</th>
          </tr>
          <template v-if="allDetailDataList.coQuesion.length>0">
            <tr v-for="(item, i) in allDetailDataList.coQuesion" @click="jumpToDetail(item)">
              <td>{{item.deviceName}}</td>
              <td>{{item.beginTime}}</td>
              <td :class="item.temp==0 ? 'red-text': ''">{{item.temp}}</td>
              <td>{{item.CO}}</td>
              <td :style="'color:'+item.o3Quality.color">{{item.coQuality.quality}}</td>
            </tr>
          </template>
          <tr v-else>
            <td colspan="5"><div class="nodata green-text">CO质量优/良</div></td>
          </tr>
        </table>
      </div>
      <div class="five-minutes-subtitle">
        O₃污染预警（ug/m³）
      </div>
      <div class="other-table">
        <table>
          <tr>
            <th>站点名称</th>
            <th>时间</th>
            <th>温度</th>
            <th>污染物</th>
            <th>等级</th>
          </tr>
          <template v-if="allDetailDataList.o3Quesion.length>0">
            <tr v-for="(item, i) in allDetailDataList.o3Quesion" @click="jumpToDetail(item)">
              <td>{{item.deviceName}}</td>
              <td>{{item.beginTime}}</td>
              <td :class="item.temp==0 ? 'red-text': ''">{{item.temp}}</td>
              <td>{{item.O3}}</td>
              <td :style="'color:'+item.o3Quality.color">{{item.o3Quality.quality}}</td>
            </tr>
          </template>
          <tr v-else>
            <td colspan="5"><div class="nodata green-text">O₃质量优/良</div></td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import {request} from "./../../request/request"
import { getQuality, getStatus, getDirection, getAirTypeName } from "./../../utils/utils"
import * as L from "leaflet";
import "leaflet/dist/leaflet.css";
import moment from "moment"

var map = null

export default {
  name: 'Nanzhuang',
  data() {
    return {
      nanzhuangDevicesList: [],
      allDeviceList: [],
      markersList: [],
      longitude: 113.001992, 
      latitude: 22.98102,
      updateTime: "",
      fiveMinList: [],
      circles: [],
      allDetailDataList: {
        stationArr: [],
        so2Arr: [],
        no2Arr: [],
        pm10Arr: [],
        pm25Arr: [],
        coArr: [],
        o3Arr: [],
        stationQuesion: [],
        so2Quesion: [],
        no2Quesion: [],
        pm10Quesion: [],
        pm25Quesion: [],
        coQuesion: [],
        o3Quesion: []
      }
    }
  },
  mounted() {
    this.setMap()
    this.getList()
    this.setFiveMin()
    setInterval(() => {
      this.getList()
      this.setFiveMin()
    }, 300000)
  },
  methods: {
    setMap() {
      map = L.map('map').setView(L.latLng(this.latitude, this.longitude), 13);
      L.tileLayer('/tile/{z}/{x}/{y}.png', {
        maxZoom: 19,
      }).addTo(map);
    },
    getList() {
      request({
        url: "device/smartMacAirDeviceList",
        method: "get",
        params: {}
      }).then(res => {
        if(res.data.successful){
          var macAirDeviceList = res.data.data;
          macAirDeviceList.forEach(item => {
            item.lon = ((parseFloat(item.lon)+0.5) / 100).toFixed(6);
            item.lat = ((parseFloat(item.lat)-0.3) / 100).toFixed(6);
            item.batteryInfo = parseInt(item.batteryInfo);
            let repairAir = new Date(item.airTime) - new Date();
            item.repairAir = repairAir > 0 ? false: true;
            let repairPM = new Date(item.pmTime) - new Date();
            item.repairPM = repairPM > 0 ? false: true;
          })
          this.allDeviceList = macAirDeviceList
          this.getNanzhaungDevice()
        }
      }).catch((err) => {
        console.log("err", err)
      });
    },
    getNanzhaungDevice() {
      request({
        url: "device/smartMacAirList",
        method: "get",
        params: {
          type: 0
        }
      }).then(res => {
        if(res.data.successful){
          this.updateTime = moment().format("YYYY-MM-DD HH:mm:ss")
          this.nanzhuangDevicesList = res.data.data 
          this.nanzhuangDevicesList&& this.nanzhuangDevicesList.forEach(item => {
              //保留两位小数
              item.SO2 = parseFloat(item.SO2).toFixed(2);
              item.NO2 = parseFloat(item.NO2).toFixed(2);
              item.PM10 = parseFloat(item.PM10).toFixed(2);
              item.PM25 = parseFloat(item.PM25).toFixed(2);
              item.CO = parseFloat(item.CO).toFixed(2);
              item.O3 = parseFloat(item.O3).toFixed(2);
              //空气质量
              item.so2Quality = getQuality(item.SO2, "SO2");
              item.no2Quality = getQuality(item.NO2, "NO2");
              item.pm10Quality = getQuality(item.PM10, "PM10");
              item.pm25Quality = getQuality(item.PM25, "PM25");
              item.coQuality = getQuality(item.CO, "CO");
              item.o3Quality = getQuality(item.O3, "O3");
              //配置在线状态
              item.status = getStatus(item.beginTime?.replace("-/g", "/"));
              item.direct = getDirection(item.direct);
              if (item.status == "维护") {
                item.color = "#e54d42";
              } else {
                item.color = "#39b54a";
              }
              const index = this.allDeviceList.findIndex(obj => obj.remark == item.macAddress)
              const curObj = this.allDeviceList[index]
              item.lon = curObj.lon;
              item.lat = curObj.lat;
              item.macAddress = curObj.macAddress;
              item.deviceName = curObj.remark;
              item.repairAir = curObj.repairAir;
              item.repairPM = curObj.repairPM;
              item.airTime = curObj.airTime;
              item.pmTime = curObj.pmTime;
              item.batteryInfo = curObj.batteryInfo;
            });
            this.setMarkers()
            this.package(this.nanzhuangDevicesList)
        }
      }).catch((err) => {
        console.log("err", err)
      });
    },
    setMarkers() {
      const t = this
      this.nanzhuangDevicesList.forEach(item => {
        const divIcon = L.divIcon({
          html: '<div style="color:'+item.color+' ;font-size: 0.3rem;width: 2.4rem;background: #fff;margin: -0.6rem -1rem;text-align: center;border: 0.03rem solid '+item.color+';border-radius: 0.4rem">'+item.deviceName+'</div>',
          className: 'textbox'
        })
        var marker2 = L.marker([item.lat, item.lon], {
          icon: divIcon
        }).addTo(map);
        const oneHtml = "<div class='map-subtitle' style='color: "+item.color+"'>"+item.deviceName+"</div>"+
                        "<div class='map-detail' style='color: "+item.so2Quality.color+"'>SO2："+item.SO2+"</div>"+
                        "<div class='map-detail' style='color: "+item.no2Quality.color+"'>NO2："+item.NO2+"</div>"+
                        "<div class='map-detail' style='color: "+item.pm10Quality.color+"'>PM10："+item.PM10+"</div>"+
                        "<div class='map-detail' style='color: "+item.pm25Quality.color+"'>PM25："+item.PM25+"</div>"+
                        "<div class='map-detail' style='color: "+item.coQuality.color+"'>CO："+item.CO+"</div>"+
                        "<div class='map-detail' style='color: "+item.o3Quality.color+"'>O3："+item.O3+"</div>"
        marker2.bindPopup(oneHtml, {maxWidth: 200, className: "station-detail"});

        const Icon = L.icon({
          iconUrl: require("@/assets/icon/ico_dw.png"),
          iconSize: [12, 15], // 加上，fixed marker在放缩5 时偏差比较大的问题
          iconAnchor: [6, 6],
          popupAnchor: [0, 0]
        })
        var marker = L.marker([item.lat, item.lon], {
          icon: Icon,
          title: item.deviceName
        }).addTo(map);
        marker.on('click', function(){
          console.log(">>>", item)
          t.jumpToDetail(item)
        })

      }) 
    },
    setFiveMin() {
      request({
        url: "device/smartMacAirMinTen",
        method: "get",
        params: {}
      }).then(res => {
        if(res.data.successful){
          this.fiveMinList = res.data.data;
          this.fiveMinList.forEach(item => {
            item.beginTime = item.beginTime.substring(item.beginTime.length - 8, item.beginTime.length - 3);
            item.airType = getAirTypeName(item.airType);
            item.quality = getQuality(item.airValue, item.airType);
          });
        }
      }).catch((err) => {
        console.log("err", err)
      });
    },
    package(list) {
      const newlist = JSON.parse(JSON.stringify(list))
      var circlesAtr = [],
        stationArr = [],
        so2Arr = [],
        no2Arr = [],
        pm10Arr = [],
        pm25Arr = [],
        coArr = [],
        o3Arr = [],
        stationQuesion = [],
        so2Quesion = [],
        no2Quesion = [],
        pm10Quesion = [],
        pm25Quesion = [],
        coQuesion = [],
        o3Quesion = [];
      newlist.forEach((item, index) => {
        circlesAtr.push({
          longitude: item.lon,
          latitude: item.lat,
          color: "#7cb5ec88",
          fillColor: "#7cb5ec88",
          radius: 50,
          strokeWidth: 2
        });
        stationArr.push({
          id: index + 1,
          longitude: item.lon,
          latitude: item.lat,
          iconPath: "/img/location.png",
          width: 15,
          height: 15,
          callout: {
            content: item.deviceName,
            color: item.color,
            fontSize: 9,
            borderWidth: 1,
            borderRadius: 8,
            borderColor: item.color,
            bgColor: '#fff',
            padding: 6,
            display: 'ALWAYS',
            textAlign: 'center'
          },
        });
        so2Arr.push({
          id: index + 1,
          longitude: item.lon,
          latitude: item.lat,
          iconPath: "/img/location.png",
          width: 15,
          height: 15,
          callout: {
            content: "SO₂：" + item.SO2 + " μg/m³",
            color: item.so2Quality.color,
            fontSize: 10,
            borderWidth: 1,
            borderRadius: 8,
            borderColor: item.so2Quality.color,
            bgColor: '#fff',
            padding: 8,
            display: 'ALWAYS',
            textAlign: 'center'
          },
        });
        no2Arr.push({
          id: index + 1,
          longitude: item.lon,
          latitude: item.lat,
          iconPath: "/img/location.png",
          width: 15,
          height: 15,
          callout: {
            content: "NO₂：" + item.NO2 + " μg/m³",
            color: item.no2Quality.color,
            fontSize: 10,
            borderWidth: 1,
            borderRadius: 8,
            borderColor: item.no2Quality.color,
            bgColor: '#fff',
            padding: 8,
            display: 'ALWAYS',
            textAlign: 'center'
          },
        });
        pm10Arr.push({
          id: index + 1,
          longitude: item.lon,
          latitude: item.lat,
          iconPath: "/img/location.png",
          width: 15,
          height: 15,
          callout: {
            content: "PM₁₀：" + item.PM10 + " μg/m³",
            color: item.pm10Quality.color,
            fontSize: 10,
            borderWidth: 1,
            borderRadius: 8,
            borderColor: item.pm10Quality.color,
            bgColor: '#fff',
            padding: 8,
            display: 'ALWAYS',
            textAlign: 'center'
          },
        });
        pm25Arr.push({
          id: index + 1,
          longitude: item.lon,
          latitude: item.lat,
          iconPath: "/img/location.png",
          width: 15,
          height: 15,
          callout: {
            content: "PM₂.₅：" + item.PM25 + " μg/m³",
            color: item.pm25Quality.color,
            fontSize: 10,
            borderWidth: 1,
            borderRadius: 8,
            borderColor: item.pm25Quality.color,
            bgColor: '#fff',
            padding: 8,
            display: 'ALWAYS',
            textAlign: 'center'
          },
        });
        coArr.push({
          id: index + 1,
          longitude: item.lon,
          latitude: item.lat,
          iconPath: "/img/location.png",
          width: 15,
          height: 15,
          callout: {
            content: "CO：" + item.CO + " mg/m³",
            color: item.coQuality.color,
            fontSize: 10,
            borderWidth: 1,
            borderRadius: 8,
            borderColor: item.coQuality.color,
            bgColor: '#fff',
            padding: 8,
            display: 'ALWAYS',
            textAlign: 'center'
          },
        });
        o3Arr.push({
          id: index + 1,
          longitude: item.lon,
          latitude: item.lat,
          iconPath: "/img/location.png",
          width: 15,
          height: 15,
          callout: {
            content: "O₃：" + item.O3 + " μg/m³",
            color: item.o3Quality.color,
            fontSize: 10,
            borderWidth: 1,
            borderRadius: 8,
            borderColor: item.o3Quality.color,
            bgColor: '#fff',
            padding: 8,
            display: 'ALWAYS',
            textAlign: 'center'
          },
        });
        if (item.status == "维护" || item.temp == 0 || item.humidity == 0) {
          stationQuesion.push(item);
        }
        if (item.so2Quality.quality != "优" && item.so2Quality.quality != "异" && item.so2Quality.quality != "良") {
          so2Quesion.push(item);
        }
        if (item.no2Quality.quality != "优" && item.no2Quality.quality != "异" && item.no2Quality.quality != "良") {
          no2Quesion.push(item);
        }
        if (item.pm10Quality.quality != "优" && item.pm10Quality.quality != "异" && item.pm10Quality.quality != "良") {
          pm10Quesion.push(item);
        }
        if (item.pm25Quality.quality != "优" && item.pm25Quality.quality != "异" && item.pm25Quality.quality != "良") {
          pm25Quesion.push(item);
        }
        if (item.coQuality.quality != "优" && item.coQuality.quality != "异" && item.coQuality.quality != "良") {
          coQuesion.push(item);
        }
        if (item.o3Quality.quality != "优" && item.o3Quality.quality != "异" && item.o3Quality.quality != "良") {
          o3Quesion.push(item);
        }
      });

      this.allDetailDataList = {
        circles: circlesAtr,
        stationArr,
        so2Arr,
        no2Arr,
        pm10Arr,
        pm25Arr,
        coArr,
        o3Arr,
        stationQuesion,
        so2Quesion,
        no2Quesion,
        pm10Quesion,
        pm25Quesion,
        coQuesion,
        o3Quesion
      }
    },
    jumpToDetail(item) {
      let index = this.nanzhuangDevicesList.findIndex(obj => obj.macAddress === item.macAddress)
      if(index<0){
        index = this.nanzhuangDevicesList.findIndex(obj => obj.deviceName === item.macAddress)
      }
      window.sessionStorage.setItem("currentOne", JSON.stringify(this.nanzhuangDevicesList[index]))
      this.$router.push({ path: '/NanZhuangOneDetail/'+item.macAddress })
    }
  }
}
</script>
<style lang="less">
  .tMapBox{
    height: 2.8rem;
    overflow: hidden;
    .map-title{
      font-size: 0.4rem;
    }
    .map-subtitle{
      font-size: 0.34rem;
      line-height: 0.46rem;
    }
    .map-detail{
      font-size: 0.26rem;
      line-height: 0.4rem;
    }
  }
  .map-box{
    position: relative;
    .updateTime{
      font-size: 0.32rem;
      position: absolute;
      right: 0.1rem;
      bottom: 0.1rem;
      z-index: 1000;
    }
  }
  .textbox{
    background-color: none;
    border: unset;
  }
  .legend-list{
    position: absolute;
    bottom: 0.2rem;
    left: 0.2rem;
    z-index: 1000;
    .legend-one{
      width: 1.4rem;
      margin: 0.04rem 0;
      display: block;
      color: #fff;
      font-size: 0.3rem;
      border-radius: 2rem;
      padding: 0.1rem;
      text-align: center;
    }
    .legend-a{
      background: #39b54a;
    }
    .legend-b{
      background: #8dc63f;
    }
    .legend-c{
      background: #fbbd08;
      color: #000;
    }
    .legend-d{
      background: #f37b1d;
    }
    .legend-e{
      background: #e54d42;
    }
    .legend-f{
      background:#6739b6;
    }
  }
  .five-minutes{
    padding: 0.4rem;
    background-color: #f5f5f5;
    .five-minutes-title{
      font-size: 0.46rem;
      font-weight: bold;
      position: relative;
      margin-bottom: 0.2rem;
      .subtitle{
        color: #afafaf;
        font-size: 0.32rem;
        position: absolute;
        right: 0rem;
        bottom: 0rem;
        font-weight: normal;
      }
    }
    .five-minutes-title::before{
        content: "";
        width: 0.14rem;
        background-color: #39b54a;
        border-radius: 0.2rem;
        display: inline-block;
        height: 0.4rem;
        margin-right: 0.3rem;
        vertical-align: middle;
      }
    .five-minutes-table{
      font-size: 0.3rem;
      background-color: #fff;
      border-radius: 0.4rem;
      margin-top: 0.6rem;
      table{
        width: 100%;
      }
      th{
        line-height: 1.2rem;
        color: #a9a9a9;
        font-weight: normal;
      }
      td{
        text-align: center;
        border-top: 0.02rem solid #eee;
        line-height: 0.8rem;
      }
    }
    .five-minutes-subtitle{
      font-size: 0.4rem;
      font-weight: bold;
      line-height: 1.2rem;
    }
    .other-table{
      font-size: 0.3rem;
      background-color: #fff;
      border-radius: 0.4rem;
      table{
        width: 100%;
      }
      th{
        line-height: 1.2rem;
        color: #a9a9a9;
        font-weight: normal;
      }
      td{
        text-align: center;
        border-top: 0.02rem solid #eee;
        line-height: 0.8rem;
      }
    }
  }
  .nodata{
    font-size: 0.34rem;
    text-align: center;
    line-height: 1rem;
  }
  .green-text{
    color: #39b54a;
  }
  .red-text{
    color: red;
  }
</style>