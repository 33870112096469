
import banner from "@/assets/banner/banner.jpg"
import intellectual1 from "@/assets/intellectual/19CN0876F.jpg"
import intellectual2 from "@/assets/intellectual/19CN0882S.jpg"
import intellectual3 from "@/assets/intellectual/19CN0883S.jpg"
import intellectual4 from "@/assets/intellectual/20CN0100F.jpg"
import intellectual5 from "@/assets/intellectual/21CN0199P-P.jpg"
import intellectual6 from "@/assets/intellectual/aisensing1.0.jpg"
import intellectual7 from "@/assets/intellectual/app1.0.jpg"
import intellectual8 from "@/assets/intellectual/AQMS1.0.jpg"
import intellectual9 from "@/assets/intellectual/AQMSnanzhuang1.0.jpg"
import intellectual10 from "@/assets/intellectual/miniprogram1.11.jpg"
import intellectual11 from "@/assets/intellectual/property-right.jpg"
import intellectual12 from "@/assets/intellectual/intellectual12.jpg"
import intellectual13 from "@/assets/intellectual/intellectual13.jpg"
import intellectual14 from "@/assets/intellectual/intellectual14.jpg"
import intellectual15 from "@/assets/intellectual/intellectual15.jpg"
import intellectual16 from "@/assets/intellectual/intellectual16.jpg"
import intellectual17 from "@/assets/intellectual/intellectual17.jpg"
import intellectual18 from "@/assets/intellectual/intellectual18.jpg"
import intellectual19 from "@/assets/intellectual/intellectual19.jpg"
import intellectual20 from "@/assets/intellectual/intellectual20.jpg"
import honor1 from "@/assets/honor/2021foshan-third.jpg"
import honor2 from "@/assets/honor/2021gba-first.jpg"
import honor3 from "@/assets/honor/2021guandong-third.jpg"
import honor4 from "@/assets/honor/2021high-tech.jpg"
import honor5 from "@/assets/honor/2021maker-first.jpg"
import honor6 from "@/assets/honor/2021nanhai-third.jpg"
import honor7 from "@/assets/honor/2022taihu-excellent.jpg"
import honor8 from "@/assets/honor/2021guangdong-excellent.jpg"
import honor9 from "@/assets/honor/202403.jpg"
import overView from "@/assets/homeIcon/middle.png"
import overViewbg from "@/assets/homeIcon/iconBottom.png"
import overViewIcon1 from "@/assets/homeIcon/fire.png"
import overViewIcon2 from "@/assets/homeIcon/environment.png"
import overViewIcon3 from "@/assets/homeIcon/home.png"
import overViewIcon4 from "@/assets/homeIcon/car.png"
import overViewIcon5 from "@/assets/homeIcon/food.png"
import overViewIcon6 from "@/assets/homeIcon/medical.png"
import phone from "@/assets/icon/phone.png"
import code from "@/assets/icon/code.png"
import codePer from "@/assets/icon/code-per.jpg"
import company from "@/assets/company.jpg"
import blueChat from "@/assets/icon/chat.png"
import bluePhone from "@/assets/icon/phone-blue.png"
import blueMap from "@/assets/icon/map.png"
import blueEmail from "@/assets/icon/email.png"
import area1 from "@/assets/icon/area1.png"
import area2 from "@/assets/icon/area2.png"
import area3 from "@/assets/icon/area3.png"
import area4 from "@/assets/icon/area4.png"
import area5 from "@/assets/icon/area5.png"
import area6 from "@/assets/icon/area6.png"
import area7 from "@/assets/icon/area7.png"
import area8 from "@/assets/icon/area8.png"
import iconBottom from "@/assets/homeIcon/iconBottom.png"


export default {
  banner,
  intellectual1,
  intellectual2,
  intellectual3,
  intellectual4,
  intellectual5,
  intellectual6,
  intellectual7,
  intellectual8,
  intellectual9,
  intellectual10,
  intellectual11,
  intellectual12,
  intellectual13,
  intellectual14,
  intellectual15,
  intellectual16,
  intellectual17,
  intellectual18,
  intellectual19,
  intellectual20,
  honor1,
  honor2,
  honor3,
  honor4,
  honor5,
  honor6,
  honor7,
  honor8,
  honor9,
  overView,
  overViewbg,
  overViewIcon1,
  overViewIcon2,
  overViewIcon3,
  overViewIcon4,
  overViewIcon5,
  overViewIcon6,
  phone,
  code,
  codePer,
  company,
  blueChat,
  blueEmail,
  blueMap,
  bluePhone,
  area1,
  area2,
  area3,
  area4,
  area5,
  area6,
  area7,
  area8,
  iconBottom
}