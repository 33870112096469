<template>
  <div>
    <div :class="isMobile ? 'product-m' : 'product'">
      <div class="word">
        <div class="word-title">我们的产品</div>
        <div class="word-gray"></div>
        <div class="word-text">PRODUCTS</div>
      </div>
      <div class="product-content">
        <div class="command-title">
          全部产品分类：
        </div>
        <div class="command-word">
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              {{currentDropdown}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="all">全部</el-dropdown-item>
              <el-dropdown-item 
                v-for="(item, index) in productList" 
                :key="item.type"
                :command="index">
                {{item.type}}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <el-row :gutter="20">
          <el-col :span="isMobile ? 24 : 8" v-for="(obj, index) in productShowList" :key="index" >
            <el-card :body-style="{ padding: '0px' }" shadow="hover" class="cardOne">
              <span class="spanImg">
                <img :src="require(`./../../assets/project/${obj.imgUrl}`)" class="image">
              </span>
              <div class="cardWord">
                <span>{{obj.name}}</span>
                <div class="bottom clearfix">
                  <h5 class="cardBottomWord" @click="enterDetail(obj)">查看详情</h5>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <div class="seeMore" v-show="productShowListAll.length > productShowList.length"> 
          <div @click="seeMore()" class="seeMoreBtn">查看更多</div>
        </div>
      </div>
    </div>
    <Theme></Theme>
  </div>
</template>

<script>
import arrJson from "../../utils/arrJson"
import Theme from "@/components/Theme.vue"

export default {
  name: 'Product',
  components: {
    "Theme": Theme, //公司概况
  },
  data() {
    return {
      isMobile: window.innerWidth < 780,
      currentDropdown: "全部",
      productList: arrJson.productList,
      productShowListAll: [],
      productShowList: []
    }
  },
  mounted() {
    this.setShowPro()
    window.addEventListener("resize", this.initFunc)
    if(window.sessionStorage.getItem("scroll")){
      setTimeout(() => {
        window.scrollTo({
          top: this.isMobile ? 180 : 500,
          behavior: 'smooth' // 平滑滚动
        });
      }, 0);
      window.sessionStorage.removeItem("scroll")
    }
  },
  methods: {
    handleCommand(index){
      if (index==="all") {
        this.currentDropdown = "全部"
        this.setShowPro()
      } else {
        this.currentDropdown = this.productList[index].type
        this.setShowPro(index)
      }
    },
    setShowPro(i){
      if(i>=0){
        this.productShowListAll = this.productList[i].data 
        this.productShowList = this.productShowListAll.slice(0, 6)
      } else {
        let list = []
        this.productList.forEach(item => {
          list = list.concat(item.data)
        })
        this.productShowListAll = list
        this.productShowList = list.slice(0, 12)
      }
    },
    seeMore() {
      const currentNum = this.productShowList.length
      if(currentNum<this.productShowListAll.length) {
        this.productShowList = this.productShowListAll.slice(0, currentNum+6)
      }
    },
    enterDetail(obj) {
      this.$router.push({ path: '/productDetail/'+obj.id })
    },
    initFunc() {
      this.isMobile = window.innerWidth < 780
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.initFunc)
  },
  props: {
    msg: String
  }
}
</script>


<style scoped lang="less">
.product{
  background: #f5f6fa;
  width: 100%;
  .word{
    padding: 40px 0;
    .word-title{
      font-size: 26px;
      line-height: 36px;
      font-weight: bold;
      color: #333333;
      letter-spacing: 2px;
      margin: 0 0 10px;
      text-align: center;
    }
    .word-gray{
      width: 80px;
      height: 2px;
      background: #d6d6d6;
      margin: 0 auto;
    }
    .word-text{
      font-size: 16px;
      line-height: 40px;
      color: #758292 ;
      text-align: center;
    }
  }
  .product-content{
    max-width: 1000px;
    padding: 0 0 70px;
    margin: 0 auto;
    overflow: hidden;
    .command-title{
      display: inline-block;
      margin: 0 10px;
    }
    .command-word{
      display: inline-block;
      cursor: pointer;
    }
    .el-card{
      margin: 10px;
      text-align: left;
      border-radius: 14px;
    }
    .cardOne{
      background: #d6dde8;
      box-shadow: #e8e8e8 1px 2px 5px;
      .spanImg{
        display: block;
        overflow: hidden;
      }
      .image{
        max-width: 100%;
        height: 250px;
        display: block;
        transition: all 0.6s;
        -ms-transition: all 0.8s;
        margin: 0 auto;
      }
      .image:hover{
        transform: scale(1.3);
        -ms-transform: scale(1.3);
      }
      .cardWord{
        padding: 14px;
        background: #fff;
        z-index: 10;
        text-align: center;
        color: #202020;
        font-size: 18px;
        height: 112px;
      }
      .cardBottomWord{
        font-size: 14px;
        color: #1f2120;
        font-weight: 100;
        border: 1px solid #303032;
        display: inline-block;
        border-radius: 20px;
        padding: 6px 30px;
        margin: 16px 0;
        background: #f5f6fa;
        cursor: pointer;
      }
    }
    .seeMore{
      text-align: center;
      .seeMoreBtn{
        font-size: 16px;
        color: #0089fe;
        border: 1px solid #0089fe;
        padding: 14px 40px;
        display: inline-block;
        margin: 50px auto 0;
        cursor: pointer;
      }
    }
  }
}
.product-m{
  background: #f5f6fa;
  width: 100%;
  .word{
    padding: 0.8rem 0;
    .word-title{
      font-size: 0.5rem;
      line-height: 0.8rem;
      font-weight: bold;
      color: #333333;
      letter-spacing: 0.04rem;
      margin: 0 0 0.2rem;
      text-align: center;
    }
    .word-gray{
      width: 1.6rem;
      height: 0.04rem;
      background: #d6d6d6;
      margin: 0 auto;
    }
    .word-text{
      font-size: 0.32rem;
      line-height: 0.8rem;
      color: #758292 ;
      text-align: center;
      padding: 0 0.2rem;
    }
  }
  .product-content{
    padding: 0 0 1rem;
    margin: 0.1rem 0.3rem;
    .command-title{
      display: inline-block;
      margin: 0 0.2rem;
      font-size: 0.4rem;
    }
    .command-word{
      display: inline-block;
      font-size: 0.6rem;
    }
    .el-card{
      margin: 0.2rem 0.4rem;
      text-align: left;
      border-radius: 0.3rem;
    }
    /deep/ .el-dropdown .el-dropdown-link:focus {
      outline: 0;
    }
    .cardOne{
      background: #d6dde8;
      box-shadow: #e8e8e8 0.02rem 0.04rem 0.1rem;
      .spanImg{
        display: block;
        overflow: hidden;
      }
      .image{
        max-width: 100%;
        height: 3.6rem;
        display: block;
        transition: all 0.6s;
        -ms-transition: all 0.8s;
        margin: 0 auto;
      }
      .image:hover{
        transform: scale(1.3);
        -ms-transform: scale(1.3);
      }
      .cardWord{
        padding: 0.3rem;
        background: #fff;
        z-index: 10;
        text-align: center;
        color: #202020;
        font-size: 0.4rem;
      }
      .cardBottomWord{
        font-size: 0.3rem;
        color: #888;
        font-weight: 400;
        border: 1px solid #303032;
        display: inline-block;
        border-radius: 0.4rem;
        padding: 0.12rem 0.6rem;
        background: #f5f6fa;
        margin: 0.3rem 0;
      }
    }
    .seeMore{
      text-align: center;
      .seeMoreBtn{
        font-size: 0.34rem;
        color: #0089fe;
        border: 0.02rem solid #0089fe;
        padding: 0.3rem 0.8rem;
        display: inline-block;
        margin: 0.6rem auto 0rem;
      }
    }
  }
}

</style>
