import axios from "axios";
// 封装请求方法
//get请求传参数使用params
//post请求传参使用data
export function request(config) {
    return new Promise((resolve, reject) => {
    //   this.$openFullScreen();
      // 创建axios实例
      const instance = axios.create({
        baseURL: "https://airapi.feelt-sport.com/"
      });
      // 发送网络请求
      instance(config)
        .then(res => {
        //   this.$loadingFullScreen.close();
          resolve(res);
        })
        .catch(err => {
          //关闭弹框
        //   this.$loadingFullScreen.close();
          setTimeout(() => {
            // this.$prompt(err, "error", "3000");
          }, 700);
          reject(err);
        });
    });
  }
  