<template>
  <div id="app">
    <div v-if="$route.fullPath.toLocaleLowerCase().indexOf('daqi')<0 && $route.fullPath.toLocaleLowerCase().indexOf('nanzhuang') <0">
      <el-container>
        <el-header>
          <Header></Header>
        </el-header>
        <el-main>
          <router-view :key="$route.fullPath"></router-view>
        </el-main>
        <el-footer>
          <Footer></Footer>
        </el-footer>
      </el-container>
      <div class="fix" v-show="!isMobile">
        <fix-part></fix-part>
      </div>
      <el-backtop :bottom="342" :right="27">
        <div :class="isMobile ? 'backtotop-m' : 'backtotop'">
          <i class="el-icon-download"></i>
        </div>
      </el-backtop>
    </div>
    <div v-else>
      <router-view :key="$route.fullPath"></router-view>
    </div>

  </div>
</template>

<script>
import Header from './layout/Header.vue'
import Footer from './layout/Footer.vue'
import FixPart from './components/FixPart.vue'

export default {
  name: 'App',
  data() {
    return {
      isMobile: window.innerWidth < 780,
    }
  },
  components: {
    Header,
    Footer,
    "fix-part": FixPart
  },
  watch: {
    $route(to, from) {
      window.scrollTo(0, 0); // 滚动到顶部
    }
  },
  mounted() {
    window.addEventListener("resize", this.initFunc)
  },
  methods: {
    initFunc() {
      this.isMobile = window.innerWidth < 780
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.initFunc)
  },
}
</script>

<style scoped lang="less">
  * {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  list-style: none;
} 
/deep/ a {
  text-decoration: none;
  color: inherit;
}
a:hover { 
  text-decoration: none; 
  color: inherit;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.el-header{
  padding: 0 !important;
  height: unset !important;
}
.el-main{
  width: 100%;
  display: block;
  margin: 0 auto;
  padding: 0;
}
.el-footer{
  padding: 0;
}
.fix{
  position: fixed;
  right: 10px;
  bottom: 200px;
  z-index: 1000;
}
.backtotop{
  background-color: #0032dd;
  color: #fff;
  font-size: 50px;
  text-align: center;
  line-height: 60px;
  border: 2px solid #51a9f3;
  transform: rotate(180deg);
  padding: 1px 10px;
  -o-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -moz-transform: rotate(180deg);
}
.backtotop:hover{
  background: #fc0230;
  border: 2px solid #f3a4b7;
}
.backtotop-m{
  font-size: 0.74rem;
  -o-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -moz-transform: rotate(180deg);
}

</style>
