var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.isMobile ? 'gain-m' : 'gain'},[_c('div',{staticClass:"intellectual"},[_vm._m(0),_c('div',{staticClass:"intellectual-content"},[_c('div',{staticClass:"intellectual-list"},_vm._l((_vm.intellectualList),function(item,index){return _c('div',{key:item.text,staticClass:"intellectual-one"},[_c('div',{staticClass:"word"},[_c('span',{staticClass:"intellectual-word-big"},[_vm._v(_vm._s(item.num))]),_c('span',{staticClass:"intellectual-word-small"},[_vm._v(_vm._s(item.unit))]),_c('div',{staticClass:"intellectual-word-text"},[_vm._v(_vm._s(item.text))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:((!_vm.isMobile && index!==(_vm.intellectualList.length-1)) 
                    || (_vm.isMobile && index!==(_vm.intellectualList.length-1) && ((index+1)%3!==0))),expression:"(!isMobile && index!==(intellectualList.length-1)) \n                    || (isMobile && index!==(intellectualList.length-1) && ((index+1)%3!==0))"}],staticClass:"gray"})])}),0)])]),_c('div',{staticClass:"standard"},[_vm._m(1),_c('div',{staticClass:"standard-content"},_vm._l((_vm.standList),function(item){return _c('div',{key:item.text,staticClass:"standard-content-one"},[_c('div',{staticClass:"leftImg"}),_c('div',{staticClass:"word"},[_c('span',{staticClass:"standard-word-num"},[_vm._v(_vm._s(item.num))]),_c('span',{staticClass:"standard-word-unit"},[_vm._v(_vm._s(item.unit))]),_c('div',{staticClass:"standard-word-text"},[_vm._v(_vm._s(item.text))])]),_c('div',{staticClass:"rightImg"})])}),0)]),_vm._m(2),_c('div',{staticClass:"standard"},[_vm._m(3),_c('div',{staticClass:"standard-content"},_vm._l((_vm.serverList),function(item){return _c('div',{key:item.text,staticClass:"standard-content-one"},[_c('div',{staticClass:"leftImg"}),_c('div',{staticClass:"word"},[_c('span',{staticClass:"standard-word-num"},[_vm._v(_vm._s(item.num))]),_c('span',{staticClass:"standard-word-unit"},[_vm._v(_vm._s(item.unit))]),_c('div',{staticClass:"standard-word-text"},[_vm._v(_vm._s(item.text))])]),_c('div',{staticClass:"rightImg"})])}),0)]),_c('div',{staticClass:"area"},[_vm._m(4),_c('div',{staticClass:"area-content"},_vm._l((_vm.areaList),function(item){return _c('div',{key:item.text,staticClass:"area-one"},[_c('div',{staticClass:"area-one-img"},[_c('img',{attrs:{"src":item.img}})]),_c('div',{staticClass:"area-one-text"},[_vm._v(_vm._s(item.text))])])}),0)]),_c('Theme',{attrs:{"bg":"#f3f4f9"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"word"},[_c('div',{staticClass:"word-title"},[_vm._v("知识产权")]),_c('div',{staticClass:"word-gray"}),_c('div',{staticClass:"word-text"},[_vm._v("INTELLECTUAL PROPERTY")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"word"},[_c('div',{staticClass:"word-title"},[_vm._v("标准")]),_c('div',{staticClass:"word-gray"}),_c('div',{staticClass:"word-text"},[_vm._v("STANDARD")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sci"},[_c('div',{staticClass:"sci-content"},[_c('span',{staticClass:"sci-title"},[_vm._v("论文SCI10篇")]),_c('span',{staticClass:"sci-text"},[_vm._v("10 SCI PAPERS")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"word"},[_c('div',{staticClass:"word-title"},[_vm._v("服务机构")]),_c('div',{staticClass:"word-gray"}),_c('div',{staticClass:"word-text"},[_vm._v("SERVICE ORGANIZATION")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"word"},[_c('div',{staticClass:"word-title"},[_vm._v("应用领域")]),_c('div',{staticClass:"word-gray"}),_c('div',{staticClass:"word-text"},[_vm._v("APPLICATION AREA")])])
}]

export { render, staticRenderFns }